import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const MainMenuStates = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { username } = useParams();

  const [home, setHome] = useState(false);
  const [search, setSearch] = useState(false);
  const [explore, setExplore] = useState(false);
  const [notifications, setNotifications] = useState(false);
  const [messages, setMessages] = useState(false);
  const [create, setCreate] = useState(false);
  const [profile, setProfile] = useState(false);

  useEffect(() => {
    const path = location.pathname.split("/").pop();

    setHome(path === "");
    setSearch(path === "search");
    setExplore(path === "explore");
    setNotifications(path === "notifications");
    setMessages(path === "messages");
    setCreate(path === "create");
    setProfile(path === `/${username}`);
  }, [location, username]);

  const handleHome = () => {
    navigate(`/`);
  };

  const handleSearch = () => {
    navigate(`/search`);
  };

  const handleExplore = () => {
    navigate(`/explore`);
  };

  const handleNotification = () => {
    navigate(`/notifications`);
  };

  const handleMessages = () => {
    navigate(`/notifications`);
  };

  const handleCreate = () => {
    navigate(`/create`);
  };

  const handleProfile = () => {
    navigate(`/${username}`);
  };

  return {
    home,
    search,
    explore,
    notifications,
    messages,
    create,
    profile,
    setHome,
    setSearch,
    setExplore,
    setNotifications,
    setMessages,
    setCreate,
    setProfile,
    handleHome,
    handleSearch,
    handleExplore,
    handleNotification,
    handleMessages,
    handleCreate,
    handleProfile,
  };
};

export default MainMenuStates;
