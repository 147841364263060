import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { MaterialSymbols } from "./MaterialSymbols";
import { formatText } from "../utils/formatText";
import { usePosts } from "../hooks/usePosts";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";
import ReactPlayer from "react-player";
import PostHeader from "./PostHeader";

function PostContent(props) {
  const users = useUsers(db);
  const posts = usePosts(db);
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevPhoto = useCallback(() => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + props.photos.length) % props.photos.length
    );
  }, [props.photos.length]);

  const nextPhoto = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % props.photos.length);
  }, [props.photos.length]);

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.code === "ArrowLeft") {
  //       prevPhoto();
  //     } else if (event.code === "ArrowRight") {
  //       nextPhoto();
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [prevPhoto, nextPhoto]);

  const repost = posts.find((post) => post.id === props?.repost);
  const user = users.find((user) => user.id === repost?.userId);
  // const author = users.find((user) => user.id === repost?.userId);

  const prevPhotoRepost = useCallback(() => {
    setCurrentIndex(
      (prevIndex) =>
        (prevIndex - 1 + repost?.photos.length) % repost?.photos.length
    );
  }, [repost?.photos.length]);

  const nextPhotoRepost = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % repost?.photos.length);
  }, [repost?.photos.length]);

  return (
    <div className="post-content">
      {props.title && <div className="title">{props.title}</div>}
      {props.text && <div className="text">{formatText(props.text)}</div>}
      {props.text &&
        ((props.photos && props.photos.length > 0) ||
          props.video ||
          props.link ||
          props.repost) && <div className="space" />}
      {props.photos && props.photos.length > 0 && (
        <div className="wrapper-photos">
          {props.photos.length > 1 && (
            <>
              <button
                onClick={() => prevPhoto()}
                className="prev-button"
                title={t("post.prev")}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                <MaterialSymbols icon="" />
              </button>
              <button
                onClick={() => nextPhoto()}
                className="next-button"
                title={t("post.next")}
              >
                <FontAwesomeIcon icon={faArrowRight} size="xl" />
              </button>
            </>
          )}
          {props.photos.length > 1 && (
            <div className="counter">{`${[currentIndex + 1]}/${
              props.photos.length
            }`}</div>
          )}
          <div className="photo-item">
            <div className="photo-back">
              <img
                src={props.photos[currentIndex]}
                alt={props.alt ? props.alt[currentIndex] : props.text}
                title={props.alt ? props.alt[currentIndex] : props.text}
                loading="eager"
                width={620}
                height={620}
              />
            </div>
            <img
              src={props.photos[currentIndex]}
              alt={props.alt ? props.alt[currentIndex] : props.text}
              title={props.alt ? props.alt[currentIndex] : props.text}
              loading="eager"
            />
          </div>
        </div>
      )}
      {props.video && (
        <div className="wrapper-video">
          <ReactPlayer
            url={props.video}
            light={props.poster}
            className="video"
            playing={true}
            loop={true}
            controls
          />
        </div>
      )}
      {props.link && (
        <div className="wrapper-link">
          {props.link.small ? (
            <a
              href={props.link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="card"
            >
              {props.link.image && (
                <div className="card-image">
                  <img
                    src={props.link.image}
                    alt={props.link.description}
                    title={props.link.description}
                    loading="eager"
                    width={120}
                    height={120}
                  />
                </div>
              )}
              <div className="card-content">
                {props.link.url && (
                  <div className="link">
                    {props.link.url
                      .replace(/https?:\/\//, "")
                      .replace(/\?.*$/, "")
                      .replace(/\/$/, "")}
                  </div>
                )}
                {props.link.title && (
                  <div className="title">{props.link.title}</div>
                )}
                {props.link.description && (
                  <div className="description" title={props.link.description}>{props.link.description}</div>
                )}
              </div>
            </a>
          ) : (
            <a
              href={props.link.url}
              target="_blank"
              rel="noopener noreferrer"
              className="card-link"
            >
              {props.link.image && (
                <div className="card-link-image">
                  <img
                    src={props.link.image}
                    alt={props.link.description}
                    title={props.link.description}
                    loading="eager"
                    width={584}
                  />
                </div>
              )}
              <div className="card-link-content">
                {props.link.url && (
                  <div className="link">
                    {props.link.url
                      .replace(/https?:\/\//, "")
                      .replace(/\?.*$/, "")
                      .replace(/\/$/, "")}
                  </div>
                )}
                {props.link.title && (
                  <div className="title">{props.link.title}</div>
                )}
                {props.link.description && (
                  <div className="description" title={props.link.description}>
                    {props.link.description}
                  </div>
                )}
              </div>
            </a>
          )}
        </div>
      )}
      {repost && (
        <div className="repost">
          <PostHeader
            {...user}
            {...repost}
            postId={repost.id}
            actions={false}
            // authorId={author.id}
          />
          {repost.title && <div className="title">{repost.title}</div>}
          {repost.text && <div className="text">{formatText(repost.text)}</div>}
          {repost.text &&
            ((repost.photos && repost.photos.length > 0) || repost.video) && (
              <div className="space" />
            )}
          {repost.photos && repost.photos.length > 0 && (
            <div className="wrapper-photos">
              {repost.photos.length > 1 && (
                <>
                  <button
                    onClick={() => prevPhotoRepost()}
                    className="prev-button"
                    title={t("post.prev")}
                  >
                    <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                    <MaterialSymbols icon="" />
                  </button>
                  <button
                    onClick={() => nextPhotoRepost()}
                    className="next-button"
                    title={t("post.next")}
                  >
                    <FontAwesomeIcon icon={faArrowRight} size="xl" />
                  </button>
                </>
              )}
              {repost.photos.length > 1 && (
                <div className="counter">{`${[currentIndex + 1]}/${
                  repost.photos.length
                }`}</div>
              )}
              <div className="photo-item">
                <div className="photo-back">
                  <img
                    src={repost.photos[currentIndex]}
                    alt={repost.text}
                    width={620}
                    style={{ maxHeight: "620px" }}
                    loading="eager"
                  />
                </div>
                <img
                  src={repost.photos[currentIndex]}
                  alt={repost.text}
                  width={620}
                  style={{ maxHeight: "620px" }}
                  loading="eager"
                />
              </div>
            </div>
          )}
          {repost.video && (
            <div className="wrapper-video">
              <ReactPlayer
                url={repost.video}
                light={repost.poster}
                className="video"
                playing={true}
                loop={true}
                controls
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default PostContent;
