import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PostHeader from "../common/PostHeader";
import {
  faBookmark,
  faComment,
  faHeart,
} from "@fortawesome/free-regular-svg-icons";
import { abbrNumber } from "../config/defult";
import {
  faArrowLeft,
  faArrowRight,
  faPlay,
  faRetweet,
} from "@fortawesome/free-solid-svg-icons";
import Avatar from "../common/Avatar";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { formatText } from "../utils/formatText";
import { useState } from "react";
import ReactPlayer from "react-player";
import { goBack } from "../utils/goBack";

function Comps() {
  const { t } = useTranslation();
  const { register, watch } = useForm();
  const [currentIndex, setCurrentIndex] = useState(0);

  const posts = [
    {
      name: "Daniel Vargas",
      username: "danielvargas",
      picture: "./media/profile-i.png",
      userId: "user1",
      show: true,
      title: "",
      postId: "post1",
      posted: "2024-10-12T14:24",
      text: "Nueva publicación. ✌🏽😋✨",
      photos: ["./media/photo-1475070929565-c985b496cb9f.avif"],
      repost: "",
    },
    {
      name: "Daniel Ospid",
      username: "danielospid",
      picture: "./media/profile.png",
      userId: "user2",
      show: true,
      title: "",
      postId: "post2",
      posted: "2024-08-01T18:00",
      photos: ["./media/contigo.png"],
      text: "Estoy enamorado de tú sonrisa y de tú forma de mirar. 😍\nBaby yo solo quiero que nos casemos y vivamos frente al mar. 🌊🏖️\n\n#Contigo #DanielOspid #Pop #Rock",
      repost: "",
    },
    {
      name: "Daniel Vargas",
      username: "danielvargas",
      picture: "./media/profile-i.png",
      userId: "user3",
      verified: false,
      show: true,
      postId: "post3",
      photos: [
        "./media/434458359_1126635771813852_9165162209919576082_n.jpg",
        "./media/profile-i.png",
        "./media/photo-2.jpg",
        "./media/post-1.png",
        "./media/profile.png",
      ],
      repost: "",
    },
    {
      name: "Daniel Vargas",
      username: "danielvargas",
      picture: "./media/profile-i.png",
      userId: "user4",
      verified: false,
      show: true,
      title: "",
      postId: "post4",
      text: "Déjame amarte hasta el infinito. 💖♾️\n\n#Contigo #DanielOspid",
      photos: [],
      repost: "post1",
    },
    {
      name: "Daniel Vargas",
      username: "danielvargas",
      picture: "./media/profile-i.png",
      userId: "user5",
      verified: false,
      show: true,
      postId: "post5",
      title: "Stray - Ciudad Muerta (2/12)",
      text: "En este vídeo, continuamos nuestra aventura en #Stray, llegamos a una nueva zona llamada Ciudad Muerta. 🙀",
      // video: "https://youtu.be/L_SqtFJRfDI?si=zd7T5tCMc0FpyXfK",
      video: "/media/clementine.mp4",
      poster: "/media/clementine.png",
      photos: [],
      repost: "",
    },
    {
      name: "Daniel Ospid",
      username: "danielospid",
      picture: "./media/profile.png",
      userId: "user6",
      verified: false,
      show: true,
      postId: "post6",
      title: "",
      text: "",
      video: "",
      poster: "",
      photos: [],
      repost: "",
      link: {
        // image: "/media/spotify-banner.png",
        image: "/media/contigo.png",
        url: "https://music.danielospid.com/contigo",
        title: "Contigo ~ Daniel Ospid",
        description: "Contigo ♡ Disponible Ahora",
      },
      small: true,
    },
    {
      name: "Daniel Vargas",
      username: "danielvargas",
      picture: "./media/profile-i.png",
      userId: "user7",
      verified: false,
      title: "",
      show: true,
      postId: "post7",
      text: "",
      photos: [],
      repost: "post6",
    },
  ];

  let likes = 1210382491;
  let comments = 482424;
  let reposts = 38225248;
  let saved = 23069623864689;

  const prevPhoto = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + posts.photos.length) % posts.photos.length
    );
  };

  const nextPhoto = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % posts.photos.length);
  };

  const repost = posts.find((post) => post.postId === post.repost);

  // console.log(repost);

  return (
    <div className="page">
      <div className="page-header">
        <button
          type="button"
          title="Volver atrás"
          className="button icon"
          onClick={() => goBack()}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="xl" />
        </button>
        <div className="title">Tipos de Publicaciones</div>
        <div className="square"></div>
      </div>
      <div
        className="page-content"
        style={{
          padding: "0",
          width: "100%",
          borderColor: "transparent",
          backgroundColor: "transparent",
        }}
      >
        {posts.map((post, index) => (
          <div className="post" key={index}>
            <PostHeader {...post} authorId={post.authorId} posted={false} />
            <div className="post-content">
              {post.title && <div className="title">{post.title}</div>}
              {post.text && <div className="text">{formatText(post.text)}</div>}
              {post.text &&
                ((post.photos && post.photos.length > 0) ||
                  post.video ||
                  post.repost ||
                  post.text) && <div className="space" />}
              {post.photos && post.photos.length > 0 && (
                <div className="wrapper-photos">
                  {post.photos.length > 1 && (
                    <>
                      <button
                        onClick={() => prevPhoto()}
                        className="prev-button"
                        title={t("post.prev")}
                      >
                        <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                      </button>
                      <button
                        onClick={() => nextPhoto()}
                        className="next-button"
                        title={t("post.next")}
                      >
                        <FontAwesomeIcon icon={faArrowRight} size="xl" />
                      </button>
                    </>
                  )}
                  {post.photos.length > 1 && (
                    <div className="counter">{`${[currentIndex + 1]}/${
                      post.photos.length
                    }`}</div>
                  )}
                  <div className="photo-item">
                    <div className="photo-back">
                      <img
                        src={post.photos[currentIndex]}
                        alt={post.text}
                        width={620}
                      />
                    </div>
                    <img
                      src={post.photos[currentIndex]}
                      alt={post.text}
                      // width={620}
                    />
                  </div>
                </div>
              )}
              {post.video && post.poster && (
                <div className="wrapper-video">
                  <ReactPlayer
                    url={post.video}
                    light={post.poster}
                    className="video"
                    controls={true}
                    playing={true}
                    loop={true}
                    config={{
                      file: {
                        attributes: {
                          controlsList: "nodownload",
                          disablePictureInPicture: true,
                        },
                      },
                    }}
                    playIcon={<FontAwesomeIcon icon={faPlay} size="3x" />}
                  />
                </div>
              )}
              {post.link && (
                <div className="wrapper-link">
                  {post.small ? (
                    <a
                      href={post.link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="card"
                    >
                      {post.link.image && (
                        <div className="card-image">
                          <img src={post.link.image} alt="" />
                        </div>
                      )}
                      <div className="card-content">
                        {post.link.url && (
                          <div className="link">
                            {post.link.url
                              .replace(/https?:\/\//, "")
                              .replace(/\?.*$/, "")
                              .replace(/\/$/, "")}
                          </div>
                        )}
                        {post.link.title && (
                          <div className="title">{post.link.title}</div>
                        )}
                        {post.link.description && (
                          <div className="description">
                            {post.link.description}
                          </div>
                        )}
                      </div>
                    </a>
                  ) : (
                    <a
                      href={post.link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="card-link"
                    >
                      {post.link.image && (
                        <div className="card-link-image">
                          <img src={post.link.image} alt="" />
                        </div>
                      )}
                      <div className="card-link-content">
                        {post.link.url && (
                          <div className="link">
                            {post.link.url
                              .replace(/https?:\/\//, "")
                              .replace(/\?.*$/, "")
                              .replace(/\/$/, "")}
                          </div>
                        )}
                        {post.link.title && (
                          <div className="title">{post.link.title}</div>
                        )}
                        {post.link.description && (
                          <div className="description">
                            {post.link.description}
                          </div>
                        )}
                      </div>
                    </a>
                  )}
                </div>
              )}
              {repost && (
                <div className="repost">
                  {repost.title && <div className="title">{repost.title}</div>}
                  {repost.text && (
                    <div className="text">{formatText(repost.text)}</div>
                  )}
                  {repost.text &&
                    ((repost.photos && repost.photos.length > 0) ||
                      repost.video ||
                      repost.text) && <div className="space" />}
                  {repost.photos && repost.photos.length > 0 && (
                    <div className="wrapper-photos">
                      {repost.photos.length > 1 && (
                        <>
                          <button
                            onClick={() => prevPhoto()}
                            className="prev-button"
                            title={t("post.prev")}
                          >
                            <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                          </button>
                          <button
                            onClick={() => nextPhoto()}
                            className="next-button"
                            title={t("post.next")}
                          >
                            <FontAwesomeIcon icon={faArrowRight} size="xl" />
                          </button>
                        </>
                      )}
                      {repost.photos.length > 1 && (
                        <div className="counter">{`${[currentIndex + 1]}/${
                          repost.photos.length
                        }`}</div>
                      )}
                      <div className="photo-item">
                        <div className="photo-back">
                          <img
                            src={repost.photos[currentIndex]}
                            alt={repost.text}
                            width={620}
                          />
                        </div>
                        <img
                          src={repost.photos[currentIndex]}
                          alt={repost.text}
                          width={620}
                        />
                      </div>
                    </div>
                  )}
                  {repost.video && (
                    <div className="wrapper-video">
                      <ReactPlayer
                        url={repost.video}
                        light={repost.poster}
                        className="video"
                        playing={true}
                        loop={true}
                        controls
                      />
                    </div>
                  )}
                  {repost.link && (
                    <div className="wrapper-link">
                      {repost.small ? (
                        <a
                          href={repost.link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="card"
                        >
                          {repost.link.image && (
                            <div className="card-image">
                              <img src={repost.link.image} alt="" />
                            </div>
                          )}
                          <div className="card-content">
                            {repost.link.url && (
                              <div className="link">
                                {repost.link.url
                                  .replace(/https?:\/\//, "")
                                  .replace(/\?.*$/, "")
                                  .replace(/\/$/, "")}
                              </div>
                            )}
                            {repost.link.title && (
                              <div className="title">{repost.link.title}</div>
                            )}
                            {repost.link.description && (
                              <div className="description">
                                {repost.link.description}
                              </div>
                            )}
                          </div>
                        </a>
                      ) : (
                        <a
                          href={repost.link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="card-link"
                        >
                          {repost.link.image && (
                            <div className="card-link-image">
                              <img src={repost.link.image} alt="" />
                            </div>
                          )}
                          <div className="card-link-content">
                            {repost.link.url && (
                              <div className="link">
                                {repost.link.url
                                  .replace(/https?:\/\//, "")
                                  .replace(/\?.*$/, "")
                                  .replace(/\/$/, "")}
                              </div>
                            )}
                            {repost.link.title && (
                              <div className="title">{repost.link.title}</div>
                            )}
                            {repost.link.description && (
                              <div className="description">
                                {repost.link.description}
                              </div>
                            )}
                          </div>
                        </a>
                      )}
                    </div>
                  )}
                  <div
                    className="copyrigth"
                    style={{
                      borderTop:
                        repost.photos < 1
                          ? "1px solid var(--compose-border-color)"
                          : "",
                    }}
                  >
                    <div className="of">De</div>
                    <div className="link">{`${repost.name}`}</div>
                  </div>
                </div>
              )}
            </div>
            <div className="post-action">
              <div className="flex">
                <button type="button" className={"button reaction"}>
                  <div className={"icon"}>
                    <FontAwesomeIcon icon={faHeart} size="xl" />
                  </div>
                  <div className={"counter"}>{abbrNumber(likes)}</div>
                </button>
                <button type="button" className="button reaction">
                  <div className="icon">
                    <FontAwesomeIcon icon={faComment} size="xl" />
                  </div>
                  <div className="counter">{abbrNumber(comments)}</div>
                </button>
                <button type="button" className="button reaction">
                  <div className="icon">
                    <FontAwesomeIcon icon={faRetweet} size="xl" />
                  </div>
                  <div className="counter">{abbrNumber(reposts)}</div>
                </button>
              </div>
              <div className="flex">
                <button type="button" className={"button reaction"}>
                  <div className={"icon"}>
                    <FontAwesomeIcon icon={faBookmark} size="lg" />
                  </div>
                  <div className={"counter"}>{abbrNumber(saved)}</div>
                </button>
              </div>
            </div>
            <div className="post-comment-input">
              <Avatar
                name={post.name}
                picture={false}
                username={post.username}
                width="34"
                height="34"
              />
              <div className="wrapper-field">
                <input
                  type="text"
                  placeholder={t("post.add_comment") + "..."}
                  {...register("comment", { required: true, maxLength: 280 })}
                  maxLength={280}
                />
                {watch("comment") && (
                  <div className="counter">{watch("comment").length}</div>
                )}
              </div>
              <button type="button" className="button submit">
                {t("post.post")}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
export default Comps;
