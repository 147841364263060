import { Link } from "react-router-dom";
import { db } from "../firebase/config";
import ProfileMenuStates from "../utils/ProfileMenuStates";
import { useTranslation } from "react-i18next";
import { MaterialSymbols } from "./MaterialSymbols";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";

function ProfileNavMenu(props) {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const user = users.find((user) => user.email === auth.email);

  const {
    home,
    photos,
    videos,
    music,
    shop,
    saved,
    loved,
    handleHome,
    handlePhotos,
    handleVideos,
    handleMusic,
    handleShop,
    handleSaved,
    handleLoved,
  } = ProfileMenuStates();

  return (
    <div className="profile-header-nav-menu">
      <nav className="profile-nav-menu">
        <Link
          to={`/${props.username}`}
          className={
            !home ? "button profile-button" : "button profile-button show"
          }
          onClick={handleHome}
          title={t("profile.posts")}
        >
          <MaterialSymbols icon="dashboard" size={28} />
        </Link>
        <Link
          to={`/${props.username}/photos`}
          className={
            !photos ? "button profile-button" : "button profile-button show"
          }
          onClick={handlePhotos}
          title={t("profile.photos")}
        >
          <MaterialSymbols icon="grid_on" size={28} />
        </Link>
        {false && (
          <Link
            to={`/${props.username}/videos`}
            className={
              !videos ? "button profile-button" : "button profile-button show"
            }
            onClick={handleVideos}
            title={t("profile.photos")}
          >
            <MaterialSymbols icon="smart_display" size={28} />
          </Link>
        )}
        {props.music && (
          <Link
            to={`/${props.username}/music`}
            className={
              !music ? "button profile-button" : "button profile-button show"
            }
            onClick={handleMusic}
            title={t("profile.music")}
          >
            <MaterialSymbols icon="music_note" size={28} />
          </Link>
        )}
        {props.shop && (
          <Link
            to={`/${props.username}/shop`}
            className={
              !shop ? "button profile-button" : "button profile-button show"
            }
            onClick={handleShop}
            title={t("profile.shop")}
          >
            <MaterialSymbols icon="storefront" size={26} />
          </Link>
        )}
        {user?.username === props.username ? (
          <>
            <Link
              to={`/${props.username}/saved`}
              className={
                !saved ? "button profile-button" : "button profile-button show"
              }
              onClick={handleSaved}
              title={t("profile.saved")}
            >
              <MaterialSymbols icon="bookmark" size={28} />
            </Link>
            {false && (
              <Link
                to={`/${props.username}/loved`}
                className={
                  !loved
                    ? "button profile-button"
                    : "button profile-button show"
                }
                onClick={handleLoved}
                title={t("profile.loved")}
              >
                <MaterialSymbols icon="favorite" size={26} />
              </Link>
            )}
          </>
        ) : null}
      </nav>
    </div>
  );
}
export default ProfileNavMenu;
