import { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";

const ProfileMenuStates = () => {
  const users = useUsers(db);
  const navigate = useNavigate();
  const location = useLocation();
  const { username } = useParams();
  const user = users.find((user) => user.username === username);

  const [home, setHome] = useState(false);
  const [photos, setPhotos] = useState(false);
  const [videos, setVideos] = useState(false);
  const [music, setMusic] = useState(false);
  const [shop, setShop] = useState(false);
  const [loved, setLoved] = useState(false);
  const [saved, setSaved] = useState(false);

  useEffect(() => {
    const path = location.pathname.split("/").pop();

    setHome(path === username || path === "");
    setPhotos(path === "photos");
    setVideos(path === "videos");
    setMusic(path === "music");
    setShop(path === "shop");
    setLoved(path === "loved");
    setSaved(path === "saved");
  }, [location, username]);

  const handleHome = () => {
    navigate(`/${user.username}`);
  };

  const handlePhotos = () => {
    navigate(`/${user.username}/photos`);
  };

  const handleVideos = () => {
    navigate(`/${user.username}/videos`);
  };

  const handleMusic = () => {
    navigate(`/${user.username}/music`);
  };

  const handleShop = () => {
    navigate(`/${user.username}/shop`);
  };

  const handleLoved = () => {
    navigate(`/${user.username}/loved`);
  };

  const handleSaved = () => {
    navigate(`/${user.username}/saved`);
  };

  return {
    home,
    photos,
    videos,
    music,
    shop,
    loved,
    saved,
    handleHome,
    handlePhotos,
    handleVideos,
    handleMusic,
    handleShop,
    handleLoved,
    handleSaved,
  };
};

export default ProfileMenuStates;