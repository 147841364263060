import { useParams } from "react-router-dom";
import { useUsers } from "../hooks/useUsers";
import { useMusic } from "../hooks/useMusic";
import { db } from "../firebase/config";
import MusicBox from "../common/MusicBox";

function ProfileMusic() {
  const users = useUsers(db);
  const music = useMusic(db);
  const { username } = useParams();
  const userSelected = users.find((user) => user.username === username);
  const posts = music.filter((post) => post.userId === userSelected?.id);

  return (
    <>
      {userSelected ? (
        <div className="profile-music">
          {posts.length > 0 ? (
            posts
              .sort((a, b) => b.date - a.date)
              .map((music, index) => <MusicBox key={index} {...music} />)
          ) : (
            <div className="code">Aún no hay música de este perfil.</div>
          )}
        </div>
      ) : null}
    </>
  );
}

export default ProfileMusic;
