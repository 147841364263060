import { useParams } from "react-router-dom";
import { useUsers } from "../hooks/useUsers";
import { usePosts } from "../hooks/usePosts";
import { db } from "../firebase/config";
import Post from "../common/Post";

function ProfileHome() {
  const users = useUsers(db);
  const posts = usePosts(db);
  const { username } = useParams();
  const userSelected = users.find((user) => user.username === username);
  const postsFiltered = posts.filter(
    (post) => post.userId === userSelected?.id
  );

  return (
    <div className="profile-home">
      {postsFiltered.length > 0 ? (
        postsFiltered
          .sort((a, b) => b.posted - a.posted)
          .map((post, index) => {
            // Buscar usuario asociado a la publicación
            const user = users.find((user) => user.id === post.userId);

            return (
              post.show && (
                <Post
                  {...user}
                  {...post}
                  key={index}
                  postId={post.id}
                  authorId={user?.id}
                />
              )
            );
          })
      ) : (
        <div className="code">Aún no hay publicaciones de este perfil.</div>
      )}
    </div>
  );
}
export default ProfileHome;
