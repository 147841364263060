import { Link } from "react-router-dom";
import { db } from "../firebase/config";
import { useTranslation } from "react-i18next";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { MaterialSymbols } from "./MaterialSymbols";
// import { formatDistanceToNowStrict } from "date-fns";
// import { es, enUS } from "date-fns/locale";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { useNotifications } from "../hooks/useNotifications";
import Avatar from "./Avatar";
import { formatDate } from "../utils/formatDate";
import { formatTime } from "../utils/formatTime";

function UserCard(props) {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const notifications = useNotifications(db);
  const currentUser = users.find((user) => user.email === auth?.email);
  const userId = currentUser?.id || null;

  const followed = Array.isArray(props.followers)
    ? props.followers.includes(userId)
    : false;

  const followers = Array.isArray(currentUser?.followers)
    ? currentUser?.followers.includes(props?.id)
    : false;

  const follow = async (targetUserId, currentUserId, currentUser) => {
    try {
      // Referencia al documento del usuario objetivo
      const targetUserRef = doc(db, "users", targetUserId);

      // Referencia al documento del usuario actual
      const currentUserRef = doc(db, "users", currentUserId);

      // Referencia a la colección de notificaciones
      const notificationsRef = collection(db, "notifications");

      const notification = notifications.find(
        (notif) =>
          notif.targetUserId === targetUserId &&
          notif.currentUserId === currentUserId &&
          notif.type === "follow"
      );

      if (followed) {
        // Eliminar al usuario actual de los seguidores
        await updateDoc(targetUserRef, {
          followers: arrayRemove(currentUserId),
        });

        // Eliminar al usuario objetivo de los seguidores
        await updateDoc(currentUserRef, {
          following: arrayRemove(targetUserId),
        });

        // Eliminar notificación de seguimiento
        if (notification?.id) {
          // Referencia al documento de la notificación
          const notificationRef = doc(db, "notifications", notification.id);

          await deleteDoc(notificationRef);
        }
      } else {
        // Agregar al usuario actual a los seguidores
        await updateDoc(targetUserRef, {
          followers: arrayUnion(currentUserId),
        });

        // Agregar al usuario objetivos a los seguidores
        await updateDoc(currentUserRef, {
          following: arrayUnion(targetUserId),
        });

        // Crear notificación de seguimiento
        await addDoc(notificationsRef, {
          targetUserId,
          currentUserId: currentUser.id,
          currentUserName: currentUser.name,
          currentUserUsername: currentUser.username,
          currentUserPicture: currentUser.picture,
          type: "follow",
          time: serverTimestamp(),
        });
      }
    } catch (error) {
      console.error("Error al actualizar los seguidores: ", error);
    }
  };

  return (
    <>
      {currentUser ? (
        <div className="user-card">
          <div className="user-info">
            <Avatar {...props} />
            <div className="user-data">
              <div className="user-details">
                {props.name && (
                  <Link to={`/${props.username}`} className="name">
                    {props.name}
                  </Link>
                )}
                {props.verified && (
                  <div className="verified" title={t("profile.verified")}>
                    <MaterialSymbols icon="verified" size="16" />
                  </div>
                )}
              </div>
              <div className="user-details">
                {props.username && (
                  <Link
                    to={`/${props.username}`}
                    className="username"
                  >{`@${props.username}`}</Link>
                )}
                {props.posted && (
                  <Link
                    to={`/${props.username}/post/${props.postId}`}
                    className="posted"
                    title={formatTime(props.posted, t("code_lang"))}
                  >
                    {" ~ "}
                    {formatDate(props.posted, t("code_lang"))}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default UserCard;
