import { db } from "../firebase/config";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import UserCard from "./UserCard";
import { MaterialSymbols } from "./MaterialSymbols";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as iconStar } from "@fortawesome/free-regular-svg-icons";
import {
  faEllipsisH,
  faStar as iconStared,
} from "@fortawesome/free-solid-svg-icons";
import {
  arrayRemove,
  arrayUnion,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { copyPostLink } from "../utils/copyPostLink";
import { useNavigate } from "react-router-dom";

function PostHeader(props) {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = users.find((user) => user.email === auth?.email);

  const following = Array.isArray(currentUser?.following)
    ? currentUser?.following.includes(props?.authorId)
    : false;

  const [menu, setMenu] = useState(false);
  const menuRef = useRef(null);

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenu(false);
    }
  };

  useEffect(() => {
    if (menu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menu]);

  const favorites = Array.isArray(currentUser?.favorites)
    ? currentUser?.favorites.includes(props?.authorId)
    : false;

  const addFavorites = async (targetUserId, currentUserId) => {
    try {
      // Referencia al documento del usuario actual
      const currentUserRef = doc(db, "users", currentUserId);

      if (favorites) {
        // Eliminar al usuario objetivo de los favoritos del usuario actual
        await updateDoc(currentUserRef, {
          favorites: arrayRemove(targetUserId),
        });
        // setFavorites(false);
      } else {
        // Agregar al usuario objetivo a los favoritos del usuario actual
        await updateDoc(currentUserRef, {
          favorites: arrayUnion(targetUserId),
        });
        // setFavorites(true);
      }
    } catch (error) {
      console.error("Error al actualizar los favoritos: ", error);
    }
  };

  const followed = Array.isArray(props.followers)
    ? props.followers.includes(currentUser?.id)
    : false;

  const follow = async (targetUserId, currentUserId) => {
    try {
      // Referencia al documento del usuario objetivo
      const targetUserRef = doc(db, "users", targetUserId);

      // Referencia al documento del usuario actual
      const currentUserRef = doc(db, "users", currentUserId);

      if (followed) {
        setMenu(false);
        // Eliminar al usuario actual de los seguidores
        await updateDoc(targetUserRef, {
          followers: arrayRemove(currentUserId),
        });

        // Eliminar al usuario objetivo de los seguidores
        await updateDoc(currentUserRef, {
          following: arrayRemove(targetUserId),
        });

        // Eliminar al usuario objetivo de los favoritos
        await updateDoc(currentUserRef, {
          favorites: arrayRemove(targetUserId),
        });
      } else {
        // Agregar al usuario actual a los seguidores
        await updateDoc(targetUserRef, {
          followers: arrayUnion(currentUserId),
        });

        // Agregar al usuario objetivos a los seguidores
        await updateDoc(currentUserRef, {
          following: arrayUnion(targetUserId),
        });
      }
    } catch (error) {
      console.error("Error al actualizar los seguidores: ", error);
    }
  };

  const postRef = doc(db, "photos", props?.postId);

  const archivePost = async () => {
    try {
      await updateDoc(postRef, { show: !props.show });
    } catch (error) {
      console.error("Error al archivar la publicación: ", error);
    }
  };

  const deletePost = async (postId) => {
    setMenu(false);
    const confirmDelete = window.confirm(
      t("alert.delete_post", { post: postId })
    );
    if (confirmDelete) {
      try {
        await deleteDoc(postRef);
      } catch (error) {
        console.error("Error al eliminar la publicación: ", error);
        alert("Hubo un error al eliminar la publicación, Inténtalo de nuevo.");
      }
    }
  };

  const editPost = () => {
    navigate(`/${props.username}/post/${props.postId}/edit`);
  };

  return (
    <div className="post-head">
      <UserCard {...props} />
      {false && currentUser?.id !== props.authorId ? (
        <>
          {following && (
            <>
              <button
                type="button"
                title={t("post.favorite")}
                className="button icon-button"
                onClick={() => addFavorites(props.authorId, currentUser?.id)}
              >
                <FontAwesomeIcon
                  icon={!favorites ? iconStar : iconStared}
                  size="xl"
                />
              </button>
            </>
          )}
        </>
      ) : null}
      {props.actions === false ? null : (
        <>
          <button
            type="button"
            className="button icon-button"
            onClick={() => handleMenu()}
            title={t("post.more")}
          >
            <FontAwesomeIcon icon={faEllipsisH} size="xl" />
          </button>
          {/* Menú de la publicación */}
          <div ref={menuRef} className={!menu ? "post-menu" : "post-menu show"}>
            {/* Botones visibles solo para el autor del post */}
            {currentUser?.id === props.authorId && (
              <>
                <button
                  type="button"
                  className="post-menu-item"
                  onClick={() => editPost()}
                >
                  <div className="wrapper">
                    <div className="icon">
                      <MaterialSymbols icon="edit_square" size="20" />
                    </div>
                    <div className="text">{t("post.edit")}</div>
                  </div>
                </button>
                <button
                  type="button"
                  className="post-menu-item"
                  onClick={() => archivePost()}
                >
                  <div className="wrapper">
                    <div className="icon">
                      <MaterialSymbols
                        icon={props.show ? "archive" : "unarchive"}
                        size="20"
                      />
                    </div>
                    <div className="text">
                      {props.show ? t("post.archive") : t("post.unarchive")}
                    </div>
                  </div>
                </button>
                <button
                  type="button"
                  className="post-menu-item"
                  onClick={() => deletePost(props.postId)}
                >
                  <div className="wrapper">
                    <div className="icon">
                      <MaterialSymbols icon="delete" size="20" />
                    </div>
                    <div className="text">{t("post.delete")}</div>
                  </div>
                </button>
              </>
            )}
            {/* Botones visibles solo otros usuarios */}
            {currentUser?.id !== props.authorId && (
              <>
                <button type="button" className="post-menu-item" disabled>
                  <div className="wrapper">
                    <div className="icon">
                      <MaterialSymbols icon="visibility_off" size="18" />
                    </div>
                    <div className="text">{t("post.dismiss")}</div>
                  </div>
                </button>
                <hr />
                <button
                  type="button"
                  className="post-menu-item"
                  onClick={() => follow(props.id, currentUser?.id)}
                >
                  <div className="wrapper">
                    <div className="icon">
                      <MaterialSymbols
                        icon={"star"}
                        fill={followed}
                        size="18"
                      />
                    </div>
                    <div className="text">
                      {followed ? t("post.favorite") : t("post.unfavorite")}
                    </div>
                  </div>
                </button>
                <button
                  type="button"
                  className="post-menu-item"
                  onClick={() => follow(props.id, currentUser?.id)}
                >
                  <div className="wrapper">
                    <div className="icon">
                      <MaterialSymbols
                        icon={followed ? "person_remove" : "person_add"}
                        size="18"
                      />
                    </div>
                    <div className="text">
                      {followed ? t("profile.unfollow") : t("profile.follow")}
                    </div>
                  </div>
                </button>
                <button type="button" className="post-menu-item" disabled>
                  <div className="wrapper">
                    <div className="icon">
                      <MaterialSymbols icon="person_off" size="20" />
                    </div>
                    <div className="text">{t("post.block")}</div>
                  </div>
                </button>
                <button type="button" className="post-menu-item" disabled>
                  <div className="wrapper">
                    <div className="icon">
                      <MaterialSymbols icon="warning" size="18" />
                    </div>
                    <div className="text">{t("post.report")}</div>
                  </div>
                </button>
              </>
            )}
            <hr />
            <button
              type="button"
              className="post-menu-item"
              onClick={() => copyPostLink(props?.username, props?.postId)}
            >
              <div className="wrapper">
                <div className="icon">
                  <MaterialSymbols icon="link" size="18" rotate="-45" />
                </div>
                <div className="text">{t("post.copyLink")}</div>
              </div>
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default PostHeader;
