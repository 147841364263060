import { useParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import { useTranslation } from "react-i18next";

import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { usePosts } from "../hooks/usePosts";
import { db } from "../firebase/config";

import Post from "../common/Post";
import { goBack } from "../utils/goBack";

function ProfileArchive() {
  const auth = useAuth(db);
  const users = useUsers(db);
  const posts = usePosts(db);
  const { t } = useTranslation();
  const { username } = useParams();
  const currentUser = users.find((user) => user.email === auth?.email);
  const userSelected = users.find((user) => user.username === username);
  const postsFiltered = posts.filter(
    (post) => post.userId === userSelected?.id
  );

  return (
    <>
      <div className="page">
        <div className="page-header">
          <button
            type="button"
            title="volver atrás"
            className="button icon"
            onClick={() => goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
          <div className="title">{t("profile.archive")}</div>
          {/* <button type="button" className="button icon" disabled></button> */}
          <div className="square"></div>
        </div>
        {currentUser?.username === username ? (
          <div className="page-content">
            {postsFiltered
              .sort((a, b) => b.posted - a.posted)
              .map((post, index) => {
                // Buscar usuario asociado a la publicación
                const user = users.find((user) => user.id === post.userId);

                return (
                  !post.show && (
                    <Post
                      {...user}
                      {...post}
                      key={index}
                      postId={post.id}
                      authorId={user?.id}
                    />
                  )
                );
              })}
          </div>
        ) : (
          <div className="page-content">
            <div className="code">No tienes acceso a está página.</div>
          </div>
        )}
      </div>
    </>
  );
}

export default ProfileArchive;
