import { useParams } from "react-router-dom";
import { db } from "../firebase/config";
import Post from "../common/Post";
import { useUsers } from "../hooks/useUsers";
import { usePosts } from "../hooks/usePosts";
import { useAuth } from "../hooks/useAuth";

function ProfileSaved() {
  const auth = useAuth(db);
  const users = useUsers(db);
  const posts = usePosts(db);
  const { username } = useParams();
  const userSelected = users.find((user) => user.username === username);
  const currentUser = users.find((user) => user.email === auth?.email)

  const postsFiltered = posts.filter(
    (post) => Array.isArray(post.saved) && post.saved.includes(userSelected?.id)
  );

  return (
    <>
      {currentUser?.username === username ? (
        <div className="profile-home">
          {postsFiltered.length > 0 ? (
            postsFiltered.map((post, index) => {
              // Buscar usuario asociado a la publicación
              const user = users.find((u) => u.id === post.userId);

              return (
                post.show && (
                  <Post
                    {...user}
                    {...post}
                    key={index}
                    postId={post.id}
                    authorId={user?.id}
                  />
                )
              );
            })
          ) : (
            <div className="code">Aún no hay publicaciones guardadas.</div>
          )}
        </div>
      ) : (
        <div className="code">No tienes acceso a está página.</div>
      )}
    </>
  );
}

export default ProfileSaved;
