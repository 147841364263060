import { Link, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";
import SideBar from "../common/SideBar";

function Feed() {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const currentUser = users.find((user) => user.email === auth?.email);

  return (
    <>
      <div className="page">
        <div className="page-header">
          <div></div>
          <div className="wrapper-buttons">
            <Link to="/" className="button primary scale">
              {t("title.foryou")}
            </Link>
            {currentUser?.following && currentUser.following.length > 0 && (
              <Link to="/following" className="button primary scale">
                {t("title.following")}
              </Link>
            )}
            {currentUser?.following.some((userId) =>
              currentUser?.followers.includes(userId)
            ) && (
              <Link to="/mutual" className="button primary scale">
                {t("title.mutual")}
              </Link>
            )}
            {currentUser?.favorites && currentUser.favorites.length > 0 && (
              <Link to="/favorites" className="button primary scale">
                {t("title.favorites")}
              </Link>
            )}
          </div>
          <div></div>
        </div>
        <div
          className="page-content"
          style={{
            padding: "0",
            width: "100%",
            borderColor: "transparent",
            backgroundColor: "transparent",
          }}
        >
          <Outlet />
        </div>
      </div>
      {false && (
        <aside className="aside">
          <SideBar />
        </aside>
      )}
    </>
  );
}

export default Feed;
