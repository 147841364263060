import { db } from "../firebase/config";
import { useUsers } from "../hooks/useUsers";
import { abbrNumber } from "../utils/abbrNumber";
import { countryName } from "../utils/countryName";
import { genderName } from "../utils/genderName";

function UsersPage() {
  const users = useUsers(db);

  // const countryName = countryCodes[user.country];

  return (
    <div className="app">
      <table cellSpacing={0}>
        <thead>
          <th>#</th>
          <th>Nombre</th>
          <th>Usuario</th>
          <th>Correo electrónico</th>
          <th>País</th>
          <th>Género</th>
          <th>Seguidores</th>
          <th>Verificado</th>
          <th>Se unió desde</th>
          {/* <th>ID</th> */}
          {/* <th>UID</th> */}
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={index}>
              <td>{index}</td>
              <td>{user.name}</td>
              <td>@{user.username}</td>
              <td>{user.email}</td>
              <td>{countryName[user.country]}</td>
              <td>{genderName[user.gender]}</td>
              <td>{abbrNumber(user.followers.length)}</td>
              <td>{user.verified ? "Sí" : "No"}</td>
              <td>
                {new Date(user.since.seconds * 1000).toLocaleDateString(
                  "es-ES",
                  {
                    day: "2-digit",
                    month: "short",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                  }
                )}
              </td>
              {/* <td>{user.id}</td> */}
              {/* <td>{user.uid}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default UsersPage;
