import { MaterialSymbols } from "./MaterialSymbols";

function MusicBox(props) {
  return (
    <>
      {props.show && (
        <div className="music-box">
          <a
            href={props.link}
            target="_blank"
            rel="noopener noreferrer"
            className="music-box-link"
          >
            <div className="music-box-cover">
              {props.covers && (
                <img
                  src={props.covers[0]}
                  alt={props.title}
                  className="cover"
                  loading="lazy"
                />
              )}
              {props.songs > 0 && (
                <div className="music-box-songs">
                  <span className="music-box-songs-icon">
                    <MaterialSymbols icon="music_note" size="14" />
                  </span>
                  <span className="music-box-songs-text">
                    {props.songs > 1 ? (
                      <>{props.songs + " Canciones"}</>
                    ) : (
                      <>{props.songs + " Canción"}</>
                    )}
                  </span>
                </div>
              )}
            </div>
            <div className="music-box-caption">
              <span className="music-box-title">
                {props.title && props.title}
              </span>
              <span className="music-box-type">
                {props.artists && props.artists.join(", ")}
                {` ~ ${props.format}`}
              </span>
            </div>
          </a>
        </div>
      )}
    </>
  );
}
export default MusicBox;
