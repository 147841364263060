import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export function useNotifications(db) {
  const [notify, setNotify] = useState([]);

  useEffect(() => {
    const notifyRef = collection(db, "notifications");

    const unsubscribe = onSnapshot(
      notifyRef,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setNotify(data);
      },
      (error) => {
        console.error("Notificaciones: Error al obtener los datos: ", error);
      }
    );

    return () => unsubscribe();
  }, [db]);

  return notify;
}
