import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { goBack } from "../utils/goBack";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet";
import { website_name } from "../config/app";

function Settings() {
  return (
    <>
      <Helmet>
        <title>Configuración - {website_name}</title>
      </Helmet>
      <div className="page">
        <div className="page-header">
          <button
            type="button"
            title="Volver atrás"
            className="button icon"
            onClick={() => goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
          <div className="title">Configuración</div>
          <div className="square"></div>
        </div>
        <div className="page-content">
          <Outlet />
        </div>
      </div>
    </>
  );
}
export default Settings;
