import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faEarthAmerica,
} from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";
import { countryName } from "../utils/countryName";
import LinkBox from "../common/LinkBox";

function ProfileAbout() {
  const users = useUsers(db);
  const { username } = useParams();
  const userSelected = users.find((user) => user.username === username);

  if (!userSelected) {
    return <div>Loading...</div>;
  }

  const country = countryName[userSelected.country] || countryName; // || 'Unknown Country'

  const links = [
    {
      link: "music.danielospid.com",
      title: "Música ~ Daniel Ospid",
    },
    {
      link: "shop.danielospid.com",
      title: "Tienda ~ Daniel Ospid",
    },
    {
      link: "cloudbook.danielvargas.dev/danielospid",
      title: "Daniel Ospid (@danielospid) ~ Cloudbook",
    },
    {
      link: "instagram.com/danielospid",
      title: "Daniel Ospid (@danielospid) • Instagram",
    },
    {
      link: "threads.net/@danielospid",
      title: "Daniel Ospid (@danielospid) • Threads",
    },
    {
      link: "twitter.com/danielospid",
      title: "Daniel Ospid (@danielospid) • Twitter",
    },
    {
      link: "facebook.com/danielospid",
      title: "Daniel Ospid (@danielospid) • Facebook",
    },
    {
      link: "bsky.app/",
      title: "Daniel Ospid (@danielospid) • Blue Sky",
    },
    {
      link: "danielvargas.dev",
      title: "",
    },
    {
      link: "cloudbook.app",
      title: "",
    },
    {
      link: "bio.link",
      title: "",
    },
    {
      link: "danielospid.bio.link",
      title: "",
    },
    {
      link: "danielvargas.bio.link",
      title: "",
    },
    {
      link: "spotify.com",
      title: "",
    },
    {
      link: "music.apple.com",
      title: "",
    },
    {
      link: "audiomack.com",
      title: "",
    },
    {
      link: "deezer.com",
      title: "",
    },
    {
      link: "music.amazon.com",
      title: "",
    },
    {
      link: "youtube.com",
      title: "",
    },
    {
      link: "music.youtube.com",
      title: "",
    },
    {
      link: "soundcloud.com",
      title: "",
    },
    {
      link: "bandlab.com",
      title: "",
    },
    {
      link: "tidal.com",
      title: "",
    },
    {
      link: "pandora.com",
      title: "",
    },
    {
      link: "napster.com/",
      title: "",
    },
    {
      link: "paypal.me/danielospid",
      title: "",
    },
    {
      link: "shopify.com",
      title: "",
    },
    {
      link: "https://markambor.com",
      title: "",
    },
    {
      link: "https://danielospid.limitedrun.com",
      title: "",
    },
  ];

  return (
    <>
      {userSelected && (
        <div className="profile-about">
          {userSelected.bio && (
            <div className="profile-block">
              <div className="profile-block-title">
                <div>Descripción corta</div>
              </div>
              <div className="profile-block-content">
                <div className="bio">{userSelected.bio}</div>
              </div>
            </div>
          )}
          <div className="profile-block">
            <div className="profile-block-title">
              <div>Enlace</div>
            </div>
            <div className="profile-block-content">
              <LinkBox link={userSelected.website} title="Daniel Ospid" />
              {links.map((link, index) => (
                <LinkBox key={index} {...link} />
              ))}
            </div>
          </div>
          <div className="profile-block">
            <div className="profile-block-title">
              <div>Información del perfil</div>
            </div>
            <div className="profile-block-content">
              {userSelected.since && (
                <div className="info-box">
                  <div className="info-box-icon">
                    <FontAwesomeIcon icon={faCalendarDay} size="xl" />
                  </div>
                  <div className="info-box-data">
                    <span className="info-box-text">
                      Se unió el{" "}
                      {new Date(
                        userSelected.since.seconds * 1000
                      ).toLocaleDateString("es", {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                      })}
                    </span>
                  </div>
                </div>
              )}
              {countryName && (
                <div className="info-box">
                  <div className="info-box-icon">
                    <FontAwesomeIcon icon={faEarthAmerica} size="xl" />
                  </div>
                  <div className="info-box-data">
                    <span className="info-box-text">{country}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfileAbout;
