import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";
import { goBack } from "../utils/goBack";
import UserCard from "../common/UserCard";
import { MaterialSymbols } from "../common/MaterialSymbols";

function Followings() {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { username } = useParams();
  const [searchTerm, setSearchTerm] = useState("");

  // Encontrar el usuario
  const user = users.find((user) => user.username === username);

  // Encontrar el usuario actual
  const currentUser = users.find((user) => user.email === auth?.email);

  // Validar que exista el usuario y sus seguidores
  const following = user?.following?.map((id) =>
    users.find((user) => user.id === id)
  );

  // Función para manejar cambios en el input de búsqueda
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // Filtrar usuarios por nombre o nombre de usuario o por correo
  const filteredUsers = following?.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username.toLowerCase().includes(searchTerm.toLowerCase())
    //   || user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const goSwitch = () => {
    navigate(`/${username}/followers`);
  };

  return (
    <div className="page">
      <div className="page-header">
        <button type="button" className="button icon" onClick={() => goBack()}>
          <FontAwesomeIcon icon={faChevronLeft} size="lg" />
        </button>
        <div className="title">{t("profile.following")}</div>
        <button
          type="button"
          className="button icon"
          title="Ver seguidores"
          onClick={() => goSwitch()}
        >
          <MaterialSymbols icon="cached" size="20" />
        </button>
      </div>
      <div className="page-content">
        <div className="wrapper-field">
          <FontAwesomeIcon icon={faSearch} size="lg" />
          <input
            type="text"
            placeholder="Buscar"
            value={searchTerm}
            onChange={handleSearchChange}
            disabled={currentUser?.username === username ? false : true}
          />
        </div>
        <div className="column">
          {filteredUsers?.map((user, index) =>
            user ? <UserCard key={index} {...user} /> : null
          )}
        </div>
      </div>
    </div>
  );
}
export default Followings;
