import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBookmark,
  faHeart,
  faRetweet,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  faComment,
  faBookmark as iconBookmark,
  faHeart as iconHeart,
} from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  // getDoc,
  serverTimestamp,
  // setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase/config";
import { abbrNumber } from "../utils/abbrNumber";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { useNotifications } from "../hooks/useNotifications";

function PostAction(props) {
  const auth = useAuth(db);
  const users = useUsers(db);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { register, handleSubmit, watch, reset } = useForm();
  const notifications = useNotifications(db);
  const currentUser = users.find((user) => user.email === auth?.email);
  const userId = currentUser?.id || null;

  // love
  const loved = Array.isArray(props.likes)
    ? props.likes.includes(userId)
    : false;

  const giveLove = async (postId, targetUserId, currentUserId) => {
    try {
      // Referencia al documento de la publicación
      const postRef = doc(db, "photos", postId);

      // Referencia a la colección de notificaciones
      const notificationsRef = collection(db, "notifications");

      const notification = notifications.find(
        (notif) =>
          notif.targetUserId === targetUserId &&
          notif.currentUserId === currentUserId &&
          notif.type === "like"
      );

      if (loved) {
        // Eliminar al usuario actual de los me gustas de la publicación
        await updateDoc(postRef, {
          likes: arrayRemove(currentUserId),
        });

        // Eliminar notificación de seguimiento
        if (notification?.id) {
          // Referencia al documento de la notificación
          const notificationRef = doc(db, "notifications", notification.id);
          await deleteDoc(notificationRef);
        }
      } else {
        // Agregar al usuario actual a los me gustas de la publicación
        await updateDoc(postRef, {
          likes: arrayUnion(currentUserId),
        });

        // Crear notificación de seguimiento
        await addDoc(notificationsRef, {
          targetUserId,
          currentUserId: currentUser.id,
          currentUserName: currentUser.name,
          currentUserUsername: currentUser.username,
          currentUserPicture: currentUser.picture,
          username: props.username,
          postId,
          type: "like",
          time: serverTimestamp(),
        });
      }
    } catch (error) {
      console.error("Error al dar like: ", error);
    }
  };

  // comment

  const commentPost = () => {
    navigate(`/${props.username}/post/${props.postId}`);
  };

  // share
  const [repost, setRepost] = useState(true);
  const [countRepost, setCountRepost] = useState(0);

  const sharePost = () => {
    setRepost(!repost);
    if (repost === false) {
      setCountRepost(countRepost + 1);
    } else {
      setCountRepost(countRepost - 1);
    }
  };

  // save
  const saved = Array.isArray(props.saved)
    ? props.saved.includes(userId)
    : false;

  const savePost = async (postId, currentUserId) => {
    try {
      // Referencia al documento de la publicación
      const postRef = doc(db, "photos", postId);

      if (saved) {
        // Eliminar al usuario actual de los guardados de la publicación
        await updateDoc(postRef, { saved: arrayRemove(currentUserId) });
      } else {
        // Agregar al usuario actual a los guardados de la publicación
        await updateDoc(postRef, { saved: arrayUnion(currentUserId) });
      }
    } catch (error) {
      console.error("Error al guardar la publicación: ", error);
    }
  };

  const onSubmit = async (data) => {
    if (!data.text || data.text.trim().length === 0) {
      console.error("El comentario no puede estar vacío.");
      return;
    }

    if (data.text.length > 280) {
      console.error("El comentario excede los 180 caracteres.");
      return;
    }

    try {
      // Referencia al documento de la publicación
      const postRef = doc(db, "photos", props.postId);
      await addDoc(postRef, {
        text: data.text.trim(),
        userId: currentUser.id,
        posted: serverTimestamp(),
        likes: [],
        comments: [],
      });

      reset();
    } catch (error) {
      console.error("Error al añadir el comentario: ", error);
    }
  };

  return (
    <>
      <div className="post-action">
        <div className="flex">
          <button
            type="button"
            title={t("post.like")}
            className={"button reaction"}
            onClick={() => giveLove(props.postId, props.userId, userId)}
          >
            <div className={loved ? "icon red" : "icon"}>
              {/* <MaterialSymbols
                icon="favorite"
                color="inherit"
                fill={loved}
                size="22"
              /> */}
              <FontAwesomeIcon icon={loved ? faHeart : iconHeart} size="xl" />
            </div>
            {props.likes && props.likes.length > 0 && (
              <div className={loved ? "counter red" : "counter"}>
                {abbrNumber(props.likes.length)}
              </div>
            )}
          </button>
          <button
            type="button"
            title={t("post.comments")}
            className="button reaction"
            onClick={() => commentPost()}
          >
            <div className="icon">
              <FontAwesomeIcon icon={faComment} size="xl" />
            </div>
            {props.comments && props.comments.length > 0 && (
              <div className="counter">{abbrNumber(props.comments.length)}</div>
            )}
          </button>
          <button
            type="button"
            title={t("post.share")}
            className="button reaction"
            onClick={() => sharePost()}
            disabled
          >
            <div className="icon">
              {/* <MaterialSymbols icon="share" size="22" color="inherit" /> */}
              <FontAwesomeIcon icon={faRetweet} size="xl" />
            </div>
            {countRepost >= 1 && (
              <div className="counter">{abbrNumber(countRepost)}</div>
            )}
          </button>
        </div>
        <div className="flex">
          <button
            type="button"
            title={t("post.save")}
            className={"button reaction"}
            onClick={() => savePost(props.postId, userId)}
          >
            <div className={saved ? "icon blue" : "icon"}>
              <FontAwesomeIcon
                icon={saved ? faBookmark : iconBookmark}
                size="lg"
              />
            </div>
            {currentUser?.id === props.userId &&
              props.saved &&
              props.saved.length > 0 && (
                <div className={saved ? "counter blue" : "counter"}>
                  {abbrNumber(props.saved.length)}
                </div>
              )}
          </button>
        </div>
      </div>
      {currentUser && (
        <div className={"post-form"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Link
              to={`/${currentUser.username}`}
              className=""
              title={t("post.goToProfile")}
            >
              {currentUser.picture ? (
                <img
                  src={currentUser.picture}
                  alt={`${t("post.picture")} ${currentUser.name}`}
                  width={36}
                  height={36}
                />
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faUser}
                    size="lg"
                    title={`${t("post.picture")} ${currentUser.name}`}
                  />
                </>
              )}
            </Link>
            <div className="wrapper-field">
              <input
                type="text"
                placeholder={t("post.add_comment") + "..."}
                {...register("text", { required: true, maxLength: 280 })}
                maxLength={280}
              />
              {watch("text") && (
                <div className="counter">{watch("text").length}</div>
              )}
            </div>
            <button
              type="submit"
              className=""
              // disabled
            >
              {t("post.post")}
            </button>
          </form>
        </div>
      )}
    </>
  );
}

export default PostAction;
