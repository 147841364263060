import { useParams } from "react-router-dom";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";
import { useState } from "react";
import ReactPlayer from "react-player";
import { usePosts } from "../hooks/usePosts";

function ProfileVideos() {
  const users = useUsers(db);
  const posts = usePosts(db);
  const { username } = useParams();
  const userSelected = users.find((user) => user.username === username);
  const postsFiltered = posts.filter(
    (post) => post.userId === userSelected?.id
  );

  const [glass, setGlass] = useState(false);

  const changePhoto = (photo) => {
    // setPhotoSelected(photo);
    setGlass(true);
    // setCurrentIndex(0);
  };

  const removePhoto = () => {
    // setPhotoSelected(null);
    setGlass(false);
  };

  return (
    <>
      {/* Renderiza las fotos del usuario */}
      {userSelected ? (
        <div className="profile-videos">
          {postsFiltered
            .sort((a, b) => b.posted - a.posted)
            .map(
              (post, index) =>
                post.show &&
                post.video && (
                  <div
                    key={index}
                    className="video-box"
                    onClick={() => changePhoto()}
                  >
                    {/* <img src={post.video} alt={post.text} loading="lazy" /> */}
                    <ReactPlayer url={post.video} controls />
                    {/* {post.video && (
                      <FontAwesomeIcon icon={faPlay} size="xl" />
                    )} */}
                  </div>
                )
            )}
        </div>
      ) : null}
      {/* Modal para ver la foto seleccionada */}
      <div
        className={!glass ? "photo-page" : "photo-page show"}
        onClick={() => removePhoto()}
      ></div>
      {/* {photoSelected && (
            <div className="profile-post">
              <button
                type="button"
                className="button close"
                title={t("button.close")}
                onClick={() => removePhoto()}
              >
                <FontAwesomeIcon icon={faClose} size="xl" />
              </button>
              <button type="button" className="button options" title="Opciones">
                <FontAwesomeIcon icon={faEllipsisV} size="xl" />
              </button>
              {photoSelected.photos.length > 1 && (
                <div className="counter">{`${currentIndex + 1}/${
                  photoSelected.photos?.length
                }`}</div>
              )}
              <div className="profile-post-photos">
                {photoSelected.photos?.length > 1 && (
                  <>
                    <button
                      onClick={() => prevPhoto()}
                      className="prev-button"
                      title={t("button.prev")}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                    </button>
                    <button
                      onClick={() => nextPhoto()}
                      className="next-button"
                      title={t("button.next")}
                    >
                      <FontAwesomeIcon icon={faArrowRight} size="xl" />
                    </button>
                  </>
                )}
                <img
                  src={photoSelected.photos[currentIndex]}
                  alt={photoSelected.text}
                  className="profile-post-photo-back"
                  loading="lazy"
                />
                <div className="photo-item">
                  <img
                    src={photoSelected.photos[currentIndex]}
                    alt={photoSelected.text}
                    className="profile-post-photo"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          )} */}
    </>
  );
}
export default ProfileVideos;
