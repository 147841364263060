import { Helmet } from "react-helmet";
import { website_name } from "../config/app";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  createUserWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  sendEmailVerification,
  signOut,
} from "firebase/auth";
import { auth, db } from "../firebase/config";
import {
  addDoc,
  collection,
  getDocs,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {} from "@fortawesome/free-regular-svg-icons";
import { useState } from "react";

function Register() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setError,
  } = useForm();
  const [usernameAvailable, setUsernameAvailable] = useState(null);

  const checkUsernameExists = async (username) => {
    const q = query(collection(db, "users"), where("username", "==", username));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const onUsernameBlur = async () => {
    const username = getValues("username");
    if (username) {
      const exists = await checkUsernameExists(username);
      setUsernameAvailable(!exists);
      if (exists) {
        setError("username", {
          type: "manual",
          message: "El nombre de usuario ya existe",
        });
      } else {
        setError("username", {});
      }
    }
  };

  const onSubmit = async (data) => {
    const {
      name,
      username,
      email,
      password,
      birthdate,
      gender,
      lang,
      country,
    } = data;

    try {
      // Verificar si el correo electrónico ya está en uso
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);
      if (signInMethods.length > 0) {
        setError("email", {
          type: "manual",
          message: "El correo electrónico ya está en uso",
        });
        return;
      }

      const usernameExists = await checkUsernameExists(username);
      if (usernameExists) {
        setError("username", {
          type: "manual",
          message: "El nombre de usuario ya existe",
        });
        return;
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await sendEmailVerification(auth.currentUser);

      await addDoc(collection(db, "users"), {
        lang,
        uid: user.uid,
        name,
        username,
        email,
        bio: "",
        gender,
        country,
        password,
        birthdate,
        picture: "",
        website: "",
        followers: [],
        following: [],
        favorites: [],
        verified: false,
        since: serverTimestamp(),
      });

      await signOut(auth);

      navigate("/login");
    } catch (error) {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log("Error al registrarse", errorCode, errorMessage);
    }
  };

  return (
    <div className="register-page">
      <Helmet>
        <title>Register - {website_name}</title>
        <link rel="canonical" href={`${window.location.origin}/login`} />
      </Helmet>
      <div className="form-box">
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="form"
          autoComplete="false"
        >
          <div className="form-group">
            <input
              type="text"
              placeholder="Nombre"
              {...register("name", { required: true, autoComplete: false })}
              autoComplete="false"
            />
          </div>
          {errors.name && (
            <div className="form-group">
              <span>El nombre es obligatorio*</span>
            </div>
          )}
          <div className="form-group">
            <input
              type="text"
              placeholder="Nombre de usuario"
              {...register("username", {
                required: true,
                minLength: 3,
                maxLength: 40,
                pattern: /^[a-z_]{3,20}$/,
                autoComplete: false,
              })}
              onBlur={onUsernameBlur}
              maxLength={40}
            />
            {usernameAvailable === true && (
              <FontAwesomeIcon icon={faCheck} color="green" />
            )}
            {usernameAvailable === false && (
              <FontAwesomeIcon icon={faXmark} color="red" />
            )}
          </div>
          {/* {errors.username && (
            <div className="form-group">
              <span>El nombre de usuario ya está en uso</span>
            </div>
          )} */}
          <div className="form-group">
            <input
              type="email"
              placeholder="Correo electrónico"
              {...register("email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              })}
              autoComplete="false"
            />
          </div>
          {errors.email && (
            <div className="form-group">
              <span>El correo electrónico es obligatorio*</span>
            </div>
          )}
          <div className="form-group">
            <input
              type="password"
              placeholder="Contraseña"
              {...register("password", {
                required: true,
                minLength: 8,
                autoComplete: false,
              })}
              autoComplete="false"
            />
          </div>
          {errors.password && (
            <div className="form-group">
              <span>La contraseña es obligatoria*</span>
            </div>
          )}
          <div className="form-group">
            <input
              type="date"
              placeholder="Fecha de nacimiento"
              {...register("birthdate", {
                required: true,
                autoComplete: false,
              })}
            />
          </div>
          {errors.birthdate && (
            <div className="form-group">
              <span>La fecha de nacimiento es obligatoria*</span>
            </div>
          )}
          <div className="form-group">
            <select
              {...register("lang", { required: true, autoComplete: false })}
              defaultValue="null"
            >
              <option value="null" selected disabled>
                Seleccione su idioma
              </option>
              {/* <option value="ca">Català</option> */}
              <option value="es">Español</option>
              <option value="en">English</option>
            </select>
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
          <div className="form-group">
            <select
              {...register("gender", { required: true, autoComplete: false })}
              defaultValue="null"
            >
              <option value="null" selected disabled>
                Seleccione su género
              </option>
              <option value="male">Masculino</option>
              <option value="female">Femenino</option>
              {/* <option value="other">Otro</option> */}
            </select>
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
          {errors.gender && (
            <div className="form-group">
              <span>El género es obligatorio*</span>
            </div>
          )}
          <div className="form-group">
            <select
              {...register("country", { required: true, autoComplete: false })}
              defaultValue="null"
            >
              <option value="null" selected disabled>
                Seleccione su país
              </option>
              <optgroup label="África">
                <option value="DZ">Algeria</option>
                <option value="AO">Angola</option>
                <option value="BJ">Benin</option>
                <option value="BW">Botswana</option>
                <option value="BF">Burkina Faso</option>
                <option value="BI">Burundi</option>
                <option value="CV">Cape Verde</option>
                <option value="CM">Cameroon</option>
                <option value="CF">Central African Republic</option>
                <option value="TD">Chad</option>
                <option value="KM">Comoros</option>
                <option value="CD">Democratic Republic of the Congo</option>
                <option value="DJ">Djibouti</option>
                <option value="EG">Egypt</option>
                <option value="GQ">Equatorial Guinea</option>
                <option value="ER">Eritrea</option>
                <option value="SZ">Eswatini</option>
                <option value="ET">Ethiopia</option>
                <option value="GA">Gabon</option>
                <option value="GM">Gambia</option>
                <option value="GH">Ghana</option>
                <option value="GN">Guinea</option>
                <option value="GW">Guinea-Bissau</option>
                <option value="CI">Ivory Coast</option>
                <option value="KE">Kenya</option>
                <option value="LS">Lesotho</option>
                <option value="LR">Liberia</option>
                <option value="LY">Libya</option>
                <option value="MG">Madagascar</option>
                <option value="MW">Malawi</option>
                <option value="ML">Mali</option>
                <option value="MR">Mauritania</option>
                <option value="MU">Mauritius</option>
                <option value="MA">Morocco</option>
                <option value="MZ">Mozambique</option>
                <option value="NA">Namibia</option>
                <option value="NE">Niger</option>
                <option value="NG">Nigeria</option>
                <option value="CG">Republic of the Congo</option>
                <option value="RW">Rwanda</option>
                <option value="ST">São Tomé and Príncipe</option>
                <option value="SN">Senegal</option>
                <option value="SC">Seychelles</option>
                <option value="SL">Sierra Leone</option>
                <option value="SO">Somalia</option>
                <option value="ZA">South Africa</option>
                <option value="SS">South Sudan</option>
                <option value="SD">Sudan</option>
                <option value="TZ">Tanzania</option>
                <option value="TG">Togo</option>
                <option value="TN">Tunisia</option>
                <option value="UG">Uganda</option>
                <option value="ZM">Zambia</option>
                <option value="ZW">Zimbabwe</option>
              </optgroup>
              <optgroup label="América">
                <option value="AG">Antigua and Barbuda</option>
                <option value="AR">Argentina</option>
                <option value="BS">Bahamas</option>
                <option value="BB">Barbados</option>
                <option value="BZ">Belize</option>
                <option value="BO">Bolivia</option>
                <option value="BR">Brazil</option>
                <option value="CA">Canada</option>
                <option value="CL">Chile</option>
                <option value="CO">Colombia</option>
                <option value="CR">Costa Rica</option>
                <option value="CU">Cuba</option>
                <option value="DM">Dominica</option>
                <option value="DO">Dominican Republic</option>
                <option value="EC">Ecuador</option>
                <option value="SV">El Salvador</option>
                <option value="GD">Grenada</option>
                <option value="GT">Guatemala</option>
                <option value="GY">Guyana</option>
                <option value="HT">Haiti</option>
                <option value="HN">Honduras</option>
                <option value="JM">Jamaica</option>
                <option value="MX">Mexico</option>
                <option value="NI">Nicaragua</option>
                <option value="PA">Panama</option>
                <option value="PY">Paraguay</option>
                <option value="PE">Peru</option>
                <option value="KN">Saint Kitts and Nevis</option>
                <option value="LC">Saint Lucia</option>
                <option value="VC">Saint Vincent and the Grenadines</option>
                <option value="SR">Suriname</option>
                <option value="TT">Trinidad and Tobago</option>
                <option value="US">United States</option>
                <option value="UY">Uruguay</option>
                <option value="VE">Venezuela</option>
              </optgroup>
              <optgroup label="Asia">
                <option value="AF">Afghanistan</option>
                <option value="AM">Armenia</option>
                <option value="AZ">Azerbaijan</option>
                <option value="BH">Bahrain</option>
                <option value="BD">Bangladesh</option>
                <option value="BT">Bhutan</option>
                <option value="BN">Brunei</option>
                <option value="KH">Cambodia</option>
                <option value="CN">China</option>
                <option value="CY">Cyprus</option>
                <option value="GE">Georgia</option>
                <option value="IN">India</option>
                <option value="ID">Indonesia</option>
                <option value="IR">Iran</option>
                <option value="IQ">Iraq</option>
                <option value="IL">Israel</option>
                <option value="JP">Japan</option>
                <option value="JO">Jordan</option>
                <option value="KZ">Kazakhstan</option>
                <option value="KW">Kuwait</option>
                <option value="KG">Kyrgyzstan</option>
                <option value="LA">Laos</option>
                <option value="LB">Lebanon</option>
                <option value="MY">Malaysia</option>
                <option value="MV">Maldives</option>
                <option value="MN">Mongolia</option>
                <option value="MM">Myanmar</option>
                <option value="NP">Nepal</option>
                <option value="KP">North Korea</option>
                <option value="OM">Oman</option>
                <option value="PK">Pakistan</option>
                <option value="PS">Palestine</option>
                <option value="PH">Philippines</option>
                <option value="QA">Qatar</option>
                <option value="SA">Saudi Arabia</option>
                <option value="SG">Singapore</option>
                <option value="KR">South Korea</option>
                <option value="LK">Sri Lanka</option>
                <option value="SY">Syria</option>
                <option value="TW">Taiwan</option>
                <option value="TJ">Tajikistan</option>
                <option value="TH">Thailand</option>
                <option value="TL">Timor-Leste</option>
                <option value="TR">Turkey</option>
                <option value="TM">Turkmenistan</option>
                <option value="AE">United Arab Emirates</option>
                <option value="UZ">Uzbekistan</option>
                <option value="VN">Vietnam</option>
                <option value="YE">Yemen</option>
              </optgroup>
              <optgroup label="Europa">
                <option value="AL">Albania</option>
                <option value="AD">Andorra</option>
                <option value="AT">Austria</option>
                <option value="BY">Belarus</option>
                <option value="BE">Belgium</option>
                <option value="BA">Bosnia and Herzegovina</option>
                <option value="BG">Bulgaria</option>
                <option value="HR">Croatia</option>
                <option value="CY">Cyprus</option>
                <option value="CZ">Czech Republic</option>
                <option value="DK">Denmark</option>
                <option value="EE">Estonia</option>
                <option value="FI">Finland</option>
                <option value="FR">France</option>
                <option value="GE">Georgia</option>
                <option value="DE">Germany</option>
                <option value="GR">Greece</option>
                <option value="HU">Hungary</option>
                <option value="IS">Iceland</option>
                <option value="IE">Ireland</option>
                <option value="IT">Italy</option>
                <option value="XK">Kosovo</option>
                <option value="LV">Latvia</option>
                <option value="LI">Liechtenstein</option>
                <option value="LT">Lithuania</option>
                <option value="LU">Luxembourg</option>
                <option value="MT">Malta</option>
                <option value="MD">Moldova</option>
                <option value="MC">Monaco</option>
                <option value="ME">Montenegro</option>
                <option value="NL">Netherlands</option>
                <option value="MK">North Macedonia</option>
                <option value="NO">Norway</option>
                <option value="PL">Poland</option>
                <option value="PT">Portugal</option>
                <option value="RO">Romania</option>
                <option value="RU">Russia</option>
                <option value="SM">San Marino</option>
                <option value="RS">Serbia</option>
                <option value="SK">Slovakia</option>
                <option value="SI">Slovenia</option>
                <option value="ES">Spain</option>
                <option value="SE">Sweden</option>
                <option value="CH">Switzerland</option>
                <option value="UA">Ukraine</option>
                <option value="GB">United Kingdom</option>
                <option value="VA">Vatican City</option>
              </optgroup>
              <optgroup label="Oceanía">
                <option value="AU">Australia</option>
                <option value="FJ">Fiji</option>
                <option value="KI">Kiribati</option>
                <option value="MH">Marshall Islands</option>
                <option value="FM">Micronesia</option>
                <option value="NR">Nauru</option>
                <option value="NZ">New Zealand</option>
                <option value="PW">Palau</option>
                <option value="PG">Papua New Guinea</option>
                <option value="WS">Samoa</option>
                <option value="SB">Solomon Islands</option>
                <option value="TO">Tonga</option>
                <option value="TV">Tuvalu</option>
                <option value="VU">Vanuatu</option>
              </optgroup>
            </select>
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
          {errors.country && (
            <div className="form-group">
              <span>El país es obligatorio*</span>
            </div>
          )}
          <div className="form-group">
            <button type="submit">Registrarse</button>
          </div>
          <div className="form-group">
            <Link to={"/login"}>¿Ya tienes una cuenta?</Link>
          </div>
        </form>
      </div>
    </div>
  );
}
export default Register;
