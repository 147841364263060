import { Helmet } from "react-helmet";
import { website_name } from "../config/app";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { goBack } from "../utils/goBack";
import { MaterialSymbols } from "../common/MaterialSymbols";
import { useMessages } from "../hooks/useMessages";
import { db } from "../firebase/config";
import Avatar from "../common/Avatar";
import { useUsers } from "../hooks/useUsers";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import { formatDate } from "../utils/formatDate";

function Messages() {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const messages = useMessages(db);
  const currentUser = users.find((user) => user.email === auth?.email);
  const [searchTerm, setSearchTerm] = useState("");

  const chats = messages.map((message) => {
    // Determina si el usuario actual es el emisor o el receptor
    const isSender = currentUser?.id === message.sender;
    
    // Identifica al usuario opuesto en la conversación
    const otherUser = users.find(
      (user) => user.id === (isSender ? message.receiver : message.sender)
    );
  
    const chatPrivate =
      currentUser?.id === message.sender || currentUser?.id === message.receiver;
  
    return {
      ...message,
      otherUser,  // Guarda la info del usuario opuesto
      chatPrivate,
    };
  });

  const goMessage = (username, chatId) => {
    navigate(`/messages/${username}/${chatId}`);
  };

  return (
    <>
      <Helmet>
        <title>Mensajes ~ {website_name}</title>
      </Helmet>
      <div className="page">
        <div className="page-header">
          <button
            type="button"
            title="Volver atrás"
            className="button icon"
            onClick={() => goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
          <div className="title">Mensajes</div>
          <button type="button" className="button icon" disabled>
            <MaterialSymbols icon="settings" size="18" />
          </button>
        </div>
        <div
          className="page-content"
          style={{
            padding: 0,
            width: "100%",
            // height: "82vh",
          }}
        >
          <div
            className="wrapper-field"
            style={{ marginTop: "16px", width: "calc(100% - 32px)" }}
          >
            <FontAwesomeIcon icon={faSearch} size="lg" />
            <input
              type="text"
              placeholder="Buscar mensaje"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div
            className="column"
            style={{
              height: "100%",
              // overflowY: "scroll",
            }}
          >
            {chats
              .sort((a, b) => b.sent - a.sent)
              .map((chat, index) => {
                const user = chat.otherUser;
                return (
                  chat.chatPrivate && (
                    <div
                      className="chat"
                      key={index}
                      onClick={() => goMessage(user.username, chat.id)}
                    >
                      <Avatar {...user} />
                      <div className="chat-content">
                        <div className="chat-data">
                          {user.name && (
                            <div className="name">
                              <b>{user.name}</b>
                              {user.verified && (
                                <MaterialSymbols icon="verified" size="12" />
                              )}
                            </div>
                          )}
                          {user.username && (
                            <div className="username">@{user.username}</div>
                          )}
                          {chat?.messages
                            .sort((a, b) => b.sent.seconds - a.sent.seconds)
                            .slice(0, 1)
                            .map((message, index) => (
                              <div className="sent" key={index}>
                                {" ~ "}
                                {formatDate(message.sent, t("code_lang"))}
                                {/* {new Date(
                                  message.sent.seconds * 1000
                                ).toLocaleString(t("code_lang"), {
                                  weekday: "short",
                                  day: "2-digit",
                                  month: "short",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  second: "2-digit",
                                })} */}
                              </div>
                            ))}
                        </div>
                        {chat?.messages
                          .sort((a, b) => b.sent.seconds - a.sent.seconds)
                          .slice(0, 1)
                          .map((message, index) => (
                            <div className="chat-text" key={index}>
                              {message.text}
                            </div>
                          ))}
                      </div>
                    </div>
                  )
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
export default Messages;
