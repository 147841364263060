import { MaterialSymbols } from "./MaterialSymbols";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell as iconBell,
  faSearch,
  faMessage as iconMessage,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBell,
  faMessage,
  faSquarePlus,
} from "@fortawesome/free-regular-svg-icons";
import MainMenuStates from "../utils/MainMenuStates";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";
import Avatar from "./Avatar";

function MenuMobile(props) {
  const auth = useAuth(db);
  const users = useUsers(db);
  const currentUser = users.find((user) => user.email === auth.email);

  const { home, notifications, messages } = MainMenuStates();

  return (
    <div className="menu-mobile">
      <div className="menu-mobile-wrapper">
        <div className="menu-mobile-item">
          <Link to={"/"}>
            <MaterialSymbols icon="home" size="30" fill={home} />
          </Link>
        </div>
        <div className="menu-mobile-item">
          <Link to={"/search"}>
            <FontAwesomeIcon icon={faSearch} size="xl" />
          </Link>
        </div>
        <div className="menu-mobile-item" onClick={() => props.handleCreate()}>
          <FontAwesomeIcon icon={faSquarePlus} size="xl" />
        </div>
        <div className="menu-mobile-item">
          <Link to={"/notifications"}>
            <FontAwesomeIcon
              icon={!notifications ? faBell : iconBell}
              size="xl"
            />
          </Link>
        </div>
        <div className="menu-mobile-item">
          <Link to={"/messages"}>
            <FontAwesomeIcon
              icon={!messages ? faMessage : iconMessage}
              size="xl"
            />
          </Link>
        </div>
        <div className="menu-mobile-item">
          <Avatar {...currentUser} />
        </div>
        <div className="menu-mobile-item" onClick={() => props.handleMenu()}>
          <MaterialSymbols icon="menu" size="34" />
        </div>
      </div>
    </div>
  );
}
export default MenuMobile;
