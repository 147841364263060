import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { db } from "../firebase/config";
import { doc, updateDoc } from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";

function Language(props) {
  const { t } = useTranslation();
  const auth = useAuth(db);
  const users = useUsers(db);
  const currentUser = users.find((user) => user.email === auth?.email);

  // const handleChangeLanguage = (lang) => {
  //   i18next.changeLanguage(lang);
  // };

  const handleChangeLanguage = async (lang) => {
    i18next.changeLanguage(lang);

    if (currentUser) {
      try {
        const userRef = doc(db, "users", currentUser.id);
        await updateDoc(userRef, {
          lang: lang,
        });
        console.log("Idioma actualizado en Firebase");
      } catch (error) {
        console.error("Error al actualizar el idioma en Firebase: ", error);
      }
    }
  };

  return (
    <div
      ref={props.langRef}
      className={!props.lang ? "language" : "language show"}
    >
      <button
        type="button"
        className="button close"
        title="Cerrar"
        onClick={props.handleLang}
      >
        <FontAwesomeIcon icon={faClose} size="2xl" />
      </button>
      <div className="grid" onClick={() => props.handleLang()}>
        <label className="label">
          {t("menu.language")}: {t("lang")}
        </label>
        <button
          type="button"
          onClick={() => handleChangeLanguage("ca")}
          disabled
        >
          Català
        </button>
        <button type="button" onClick={() => handleChangeLanguage("en")}>
          English
        </button>
        <button type="button" onClick={() => handleChangeLanguage("es")}>
          Español
        </button>
        <button
          type="button"
          onClick={() => handleChangeLanguage("it")}
          disabled
        >
          Italiano
        </button>
        <button
          type="button"
          onClick={() => handleChangeLanguage("pt")}
          disabled
        >
          Português
        </button>
        <button
          type="button"
          onClick={() => handleChangeLanguage("fr")}
          disabled
        >
          Francés
        </button>
        <button
          type="button"
          onClick={() => handleChangeLanguage("dz")}
          disabled
        >
          Alemán
        </button>
        <button type="button" onClick={() => handleChangeLanguage("")} disabled>
          Japonés
        </button>
        <button type="button" onClick={() => handleChangeLanguage("")} disabled>
          Coreano
        </button>
        <button type="button" onClick={() => handleChangeLanguage("")} disabled>
          Chino
        </button>
      </div>
    </div>
  );
};

export default Language;
