import { useState } from "react";
import { Helmet } from "react-helmet";
import { website_name } from "../config/app";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faHashtag,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
// import { abbrNumber } from "../utils/abbrNumber";
import UserCard from "../common/UserCard";
import { goBack } from "../utils/goBack";
import { useNavigate } from "react-router-dom";

function Search() {
  const users = useUsers(db);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  // Función para manejar cambios en el input de búsqueda
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Filtrar usuarios con más de 1000 seguidores
  const popular = users.filter((user) => user.followers.length >= 1000);

  // Filtrar usuarios por nombre o nombre de usuario o por correo
  const filteredUsers = users.filter(
    (user) =>
      user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (user.label &&
        user.label.toLowerCase().includes(searchTerm.toLowerCase()))
    // || user.email.toLowerCase().includes(searchTerm.toLowerCase())
    // || user.website.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const goHashTag = () => {
    navigate("/hashtag");
  };

  return (
    <>
      <Helmet>
        <title>
          {t("title.search")} ~ {website_name}
        </title>
      </Helmet>
      <div className="page">
        <div className="page-header">
          <button
            type="button"
            className="button icon"
            onClick={() => goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
          <div className="title">{t("title.search")}</div>
          <button
            type="button"
            title="Buscar por #hashtags"
            className="button icon"
            onClick={() => goHashTag()}
          >
            <FontAwesomeIcon icon={faHashtag} size="lg" />
          </button>
        </div>
        <div className="page-content">
          <div className="wrapper-field">
            <input
              type="text"
              placeholder={t("title.search_placeholder")}
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <FontAwesomeIcon icon={faSearch} size="lg" className="search" />
          </div>
          <div className="column">
            {searchTerm
              ? filteredUsers
                  .sort((a, b) => b.followers.length - a.followers.length)
                  .map((user, index) => <UserCard key={index} {...user} />)
              : popular
                  .sort((a, b) => b.followers.length - a.followers.length)
                  .map((user, index) => <UserCard key={index} {...user} />)}
          </div>
        </div>
      </div>
    </>
  );
}

export default Search;
