import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function Avatar(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goToProfile = () => {
    navigate(`/${props.username}`);
  };

  return (
    <div
      className="avatar-box"
      style={{
        width: props.width ? props.width + "px" : "34px",
        minWidth: props.width ? props.width + "px" : "34px",
        height: props.height ? props.height + "px" : "34px",
        minHeight: props.height ? props.height + "px" : "34px",
      }}
      role="button"
      onClick={() => goToProfile()}
    >
      <img
        src={props.picture ? props.picture : "/images/avatar.png"}
        alt={`${t("post.picture")} ${props.name}`}
        title={`${t("post.picture")} ${props.name}`}
        className="avatar"
        loading="eager"
        width={props.width ? props.width + "px" : "34px"}
        height={props.height ? props.height + "px" : "34px"}
      />
    </div>
  );
}
export default Avatar;
