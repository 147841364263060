import React, { Suspense, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import i18next from "i18next";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { initReactI18next } from "react-i18next";
import { useAuth } from "./hooks/useAuth";
import { useUsers } from "./hooks/useUsers";
import { db } from "./firebase/config";
import { HelmetProvider } from "react-helmet-async";
import Loader from "./common/Loader";
import es from "./i18n/es.json";
import en from "./i18n/en.json";
import ca from "./i18n/ca.json";

const AppWrapper = () => {
  const [lang, setLang] = useState("es");
  const [loading, setLoading] = useState(true);

  const auth = useAuth(db);
  const users = useUsers(db);

  useEffect(() => {
    if (auth && users.length > 0) {
      const currentUser = users.find((user) => user.email === auth.email);
      if (currentUser && currentUser.lang) {
        setLang(currentUser.lang);
      }
      setLoading(false);
    } else if (auth === null) {
      setLoading(false); // Maneja el caso en que no hay usuario autenticado
    }
  }, [auth, users]);

  if (loading) {
    return <Loader />; // Puedes reemplazar esto con un componente de carga
  }

  i18next.use(initReactI18next).init({
    lng: lang,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      es: {
        translation: es,
      },
      en: {
        translation: en,
      },
      ca: {
        translation: ca,
      },
    },
  });

  const helmetContext = {};

  return (
    <I18nextProvider i18n={i18next}>
      <Suspense fallback={<Loader />}>
        <HelmetProvider context={helmetContext}>
          <App />
        </HelmetProvider>
      </Suspense>
    </I18nextProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BrowserRouter future={{ v7_relativeSplatPath: true }}>
      <AppWrapper />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
