import { useUsers } from "../hooks/useUsers";
import { usePosts } from "../hooks/usePosts";
import { db } from "../firebase/config";
import Post from "../common/Post";
import { Helmet } from "react-helmet-async";

function Home() {
  const users = useUsers(db);
  const posts = usePosts(db);
  // const user = users.find((user) => user.id === posts.id);
  // const postsFiltered = posts.filter((post) => post.picture);

  // verificar si el autor de la publicaciòn cumple los requisitos para salir en el feed, para ti.
  // el usuario debe tener, foto de perfil, por lo menos 6 meses de antiguedad, más de 100 seguidores.
  // a cada usuario le apareceran publicaciones según sus preferencias de visualización.

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${window.location.origin}`} />
      </Helmet>
      {posts
        .sort((a, b) => b.posted - a.posted)
        .map((post, index) => {
          // Buscar la información del autor de la publicación.
          const user = users.find((user) => user.id === post.userId);

          return (
            post.show && (
              <Post
                {...user}
                {...post}
                key={index}
                postId={post.id}
                authorId={user?.id}
              />
            )
          );
        })}
    </>
  );
}

export default Home;
