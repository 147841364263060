import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloud } from "@fortawesome/free-solid-svg-icons";

function Logo(props) {
  return (
    <Link to="/" className="logo" onClick={props.click}>
      <FontAwesomeIcon size="2x" icon={faCloud} />
    </Link>
  );
}

export default Logo;
