import {
  faArrowLeft,
  faArrowRight,
  faCloudArrowUp,
  faEllipsisH,
  faSquarePollHorizontal,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { goBack } from "../utils/goBack";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { db, storage } from "../firebase/config";
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import {
  addDoc,
  arrayUnion,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import axios from "axios";
import UserCard from "../common/UserCard";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import ReactPlayer from "react-player";
import { MaterialSymbols } from "../common/MaterialSymbols";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { useParams } from "react-router-dom";
import { usePosts } from "../hooks/usePosts";

function Compose() {
  const auth = useAuth(db);
  const posts = usePosts(db);
  const users = useUsers(db);
  const { postId } = useParams();
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [video, setVideo] = useState(false);
  const currentUser = users.find((user) => user.email === auth?.email);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm();

  const [currentIndex, setCurrentIndex] = useState(0);

  // Cambiar a la imagen anterior
  const prevPhoto = useCallback(() => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + files.length) % files.length
    );
  }, [files.length]);

  // Cambiar a la imagen siguiente
  const nextPhoto = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % files.length);
  }, [files.length]);

  const handleFileChange = (event) => {
    const selectedFiles = Array.from(event.target.files);

    setFiles((prevFiles) => {
      // Limitar a 10 imágenes en total
      if (prevFiles.length + selectedFiles.length > 10) {
        alert("Solo puedes subir hasta 10 imágenes.");
        return prevFiles; // No añadir más imágenes
      }

      const newFileURLs = selectedFiles.map((file) => ({
        file,
        url: URL.createObjectURL(file),
      }));
      return [...prevFiles, ...newFileURLs];
    });

    setPhotos(true);
  };

  const handleRemoveFile = (indexToRemove) => {
    setFiles((prevFiles) => {
      const fileToRemove = prevFiles[indexToRemove];
      if (fileToRemove?.url) {
        URL.revokeObjectURL(fileToRemove.url);
      }

      const newFiles = prevFiles.filter((_, index) => index !== indexToRemove);

      // Ajustar el índice de la foto actual
      if (indexToRemove === currentIndex && newFiles.length > 0) {
        setCurrentIndex((prevIndex) =>
          prevIndex >= newFiles.length ? newFiles.length - 1 : prevIndex
        );
      } else if (indexToRemove < currentIndex) {
        setCurrentIndex((prevIndex) => prevIndex - 1);
      }

      return newFiles;
    });
  };

  const uploadFile = async (file) => {
    const username = currentUser.username;
    const storageRef = ref(storage, `users/${username}/photos/${v4()}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    return url;
  };

  const onSubmit = async (data) => {
    // Validaciones: Asegurar que hay texto, imágenes, o ambos
    if (!data.text && files.length === 0) {
      alert(
        "No puedes publicar un post vacío. Agrega texto, imagenes o ambos."
      );
      return;
    }

    if (surveyItems.some((item) => item.trim() === "")) {
      setError("Todos los ítems deben tener contenido.");
      return;
    }

    try {
      // Subir las imágenes (si existen) y obtener las URLs
      const photoURLs =
        files.length > 0
          ? await Promise.all(files.map((file) => uploadFile(file)))
          : [];

      const userId = currentUser.id;

      // Crear el objeto de datos para la publicación
      const posts = collection(db, "photos");
      await addDoc(posts, {
        userId: userId,
        text: data.text || "",
        photos: photoURLs,
        title: data.title || "",
        video: data.video || "",
        poster: data.poster || "",
        link: data.link || "",
        repost: data.repost || "",
        posted: serverTimestamp(),
        survey: arrayUnion(),
        show: true,
        likes: [],
        comments: [],
        reposts: [],
        saved: [],
        view: [],
      });

      // Resetear el formulario y estado
      reset();
      setFiles([]);
      setPhotos(false);
      setVideo(false);
      setLink(false);
      setSurvey(false);
    } catch (error) {
      console.error("Error al crear la publicación:", error);
      alert("Hubo un error al crear la publicación. Inténtalo de nuevo.");
    }
  };

  const handleVideo = () => {
    setVideo(!video);
  };

  const [poster, setPoster] = useState("");

  const handlePosterChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Generar un enlace temporal para la imagen subida
    const objectURL = URL.createObjectURL(file);

    // Actualizar el estado y el input con el enlace
    setPoster(objectURL);
    setValue("poster", objectURL); // Actualiza el valor en el form
  };

  const [url, setUrl] = useState("");
  const [link, setLink] = useState(false);
  const [metaData, setMetaData] = useState(null);

  const handleLink = () => {
    setLink(!link);
  };

  const fetchMetaData = async () => {
    try {
      const response = await axios.get(`http://localhost:4000/debug`, {
        params: { url },
      });

      //   Filtrar solo los metadatos relevantes
      const meta = response.data;
      setMetaData({
        image: meta["og:image"],
        title: meta["og:title"],
        description: meta["og:description"],
        url: meta["og:url"],
      });
    } catch (error) {
      console.error("Error fetching Open Graph data:", error);
    }
  };

  const [survey, setSurvey] = useState(false);
  const [surveyItems, setSurveyItems] = useState([{ text: "", votes: [] }]);
  const [error, setError] = useState("");

  // console.log("Opciones:", surveyItems);

  const handleSurvey = () => {
    setSurvey(!survey);
  };

  const handleInputChange = (value, index) => {
    if (value.length > 80) {
      setError("Cada ítem debe tener como máximo 80 caracteres.");
      return;
    }
    setError("");
    setSurveyItems((prevItems) =>
      prevItems.map((item, i) => (i === index ? value : item))
    );
  };

  const handleAddItem = () => {
    if (surveyItems.length >= 4) {
      setError("No puedes agregar más de 4 ítems.");
      return;
    }
    setError("");
    setSurveyItems([...surveyItems, ""]);
  };

  const handleRemoveItem = (index) => {
    setSurveyItems((prevItems) => prevItems.filter((_, i) => i !== index));
  };

  const handleSubmitX = () => {
    if (surveyItems.some((item) => item.trim() === "")) {
      setError("Todos los ítems deben tener contenido.");
      return;
    }

    // reset();
    setSurvey(false);
    setError("");
    // Aquí puedes manejar el envío de los datos de la encuesta
  };

  const postSelected = posts.find((post) => post.id === postId);

  const [text, setText] = useState("");

  console.log(postSelected);

  return (
    <div className="page">
      <div className="page-header">
        <button
          type="button"
          title="Volver atrás"
          className="button icon"
          onClick={() => goBack()}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="xl" />
        </button>
        {/* <div className="title">Compositor</div> */}
        <div className="title">{postId}</div>
        <div className="square"></div>
      </div>
      <div
        className="page-content"
        style={{
          padding: 0,
          width: "100%",
          borderColor: "transparent",
          backgroundColor: "transparent",
        }}
      >
        <div className="post">
          <div className="post-head">
            {currentUser?.id !== undefined ? (
              <UserCard {...currentUser} />
            ) : (
              <UserCard
                picture="/avatar.png"
                name="Nombre"
                username="usuario"
                verified={false}
              />
            )}
            <button type="button" className="button icon" disabled>
              <FontAwesomeIcon icon={faEllipsisH} size="lg" />
            </button>
          </div>
          <form onSubmit={handleSubmit(onSubmit)} className="form">
            {/* Título */}
            {(video || watch("title")) && (
              <div className="wrapper-field">
                <input
                  type="text"
                  maxLength={120}
                  placeholder="Escribe el título del vídeo"
                  {...register("title", {
                    maxLength: {
                      value: 120,
                      message: t("post.textLimit"),
                    },
                  })}
                  className="title"
                />
                {watch("title") && (
                  <div className="counter">
                    {watch("title").length + `/120`}
                  </div>
                )}
              </div>
            )}
            {/* Texto */}
            <div className="wrapper-field">
              <textarea
                rows={2}
                // value={postSelected.text}
                maxLength={280}
                className="text"
                value={text}
                placeholder={t("post.text")}
                onChange={() => setText(watch("text"))}
                {...register("text", {
                  maxLength: {
                    value: 280,
                    message: t("post.textLimit"),
                  },
                })}
              ></textarea>
              {watch("text") && (
                <div className="counter">{watch("text").length + `/280`}</div>
              )}
            </div>
            {errors.title && (
              <span className="error">{errors.title.message}</span>
            )}
            {errors.text && (
              <span className="error">{errors.text.message}</span>
            )}
            {/* Fotos */}
            {photos && files.length > 0 && (
              <div className="wrapper-photos">
                {files.length > 1 && (
                  <>
                    <button
                      type="button"
                      title={t("post.prev")}
                      className="prev-button"
                      onClick={() => prevPhoto()}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                    </button>
                    <button
                      type="button"
                      title={t("post.next")}
                      className="next-button"
                      onClick={() => nextPhoto()}
                    >
                      <FontAwesomeIcon icon={faArrowRight} size="xl" />
                    </button>
                  </>
                )}
                {files.length > 1 && (
                  <div className="counter">{`${[currentIndex + 1]}/${
                    files.length
                  }`}</div>
                )}
                <button
                  type="button"
                  className="button icon"
                  title={t("button.delete")}
                  onClick={() => handleRemoveFile(currentIndex)}
                >
                  <FontAwesomeIcon icon={faTrashCan} size="lg" />
                </button>
                {files.length > 0 && (
                  <div className="photo-item">
                    <div className="photo-back">
                      <img
                        src={files[currentIndex]?.url || ""}
                        alt=""
                        // alt={data.text ? props.text : ""}
                        style={{ display: files.length > 0 ? "block" : "none" }}
                        width={620}
                      />
                    </div>
                    <img
                      src={files[currentIndex]?.url || ""}
                      alt=""
                      //   alt={props.text ? props.text : ""}
                      style={{ display: files.length > 0 ? "block" : "none" }}
                    />
                  </div>
                )}
              </div>
            )}
            {/* Vídeo de YouTube */}
            {watch("video" && "poster") && (
              <ReactPlayer
                url={watch("video")}
                light={watch("poster")}
                className="video"
              />
            )}
            {metaData && (
              <a
                href={metaData.url}
                target="_blank"
                rel="noopener noreferrer"
                className="card"
              >
                {metaData.image && (
                  <div className="card-image">
                    <img src={metaData.image} alt="" />
                  </div>
                )}
                <div className="card-content">
                  {metaData.url && <div className="link">{metaData.url}</div>}
                  {metaData.title && (
                    <div className="title">{metaData.title}</div>
                  )}
                  {metaData.description && (
                    <div className="description">{metaData.description}</div>
                  )}
                </div>
              </a>
            )}
            {/* Inputs de video  */}
            {(!files || video) && (
              <div className="column">
                <div className="wrapper-field">
                  <input
                    type="url"
                    placeholder="Ingresa el enlace del vídeo en YouTube: https://youtu.be/path?si=token&t=360"
                    {...register("video", { required: true })}
                  />
                </div>
                <div className="wrapper-field">
                  <input
                    type="url"
                    placeholder="Ingresa el enlace de la miniatura del vídeo"
                    {...register("poster", { required: true })}
                    disabled={poster ? true : false}
                  />
                  {false && poster && (
                    <button
                      type="button"
                      className="button icon"
                      onClick={() => setPoster("")}
                    >
                      <FontAwesomeIcon icon={faTrashCan} />
                    </button>
                  )}
                  {false && (
                    <button type="button" className="button icon">
                      <input
                        type="file"
                        accept="image/*"
                        {...register("poster")}
                        onChange={handlePosterChange}
                        multiple
                        hidden
                        id="poster"
                      />
                      <label htmlFor="poster">
                        <FontAwesomeIcon icon={faCloudArrowUp} size="lg" />
                      </label>
                    </button>
                  )}
                </div>
              </div>
            )}
            {/* inputs */}
            {link && (
              <div className="column">
                <div className="wrapper-field">
                  <input
                    type="text"
                    // value={url}
                    // onKeyDown={fetchMetaData}
                    placeholder="Ingresa un enlace"
                    onChange={(e) => setUrl(e.target.value)}
                    {...register("link", { required: true })}
                  />
                  <button
                    type="button"
                    className="button submit"
                    onClick={() => fetchMetaData()}
                  >
                    Buscar
                  </button>
                </div>
              </div>
            )}
            {surveyItems.length > 0 && (
              <div className="wrapper-survey">
                {surveyItems.map(
                  (item, index) =>
                    surveyItems[index].length > 0 && (
                      <div key={index} className="item">
                        <div className="text">{item}</div>
                        <div className="counter">{0}%</div>
                      </div>
                    )
                )}
              </div>
            )}
            {survey && (
              <div className="column">
                {surveyItems.map((item, index) => (
                  <div key={index} className="wrapper-field">
                    <input
                      type="text"
                      maxLength={80}
                      placeholder={`Opción ${index + 1}`}
                      value={item.text}
                      onChange={(e) => handleInputChange(e.target.value, index)}
                    />
                    <button
                      type="button"
                      className="button primary"
                      onClick={() => handleRemoveItem(index)}
                      disabled={surveyItems.length === 1}
                    >
                      {/* <MaterialSymbols icon="delete" size="20" /> */}
                      Eliminar
                    </button>
                  </div>
                ))}
                <div className="wrapper-field">
                  {surveyItems.length < 4 && (
                    <button
                      type="button"
                      className="button primary"
                      style={{ width: "100%" }}
                      onClick={handleAddItem}
                    >
                      Añadir {surveyItems.length > 0 ? "otra" : "una"} opción
                    </button>
                  )}
                  {true && surveyItems.length > 0 && (
                    <button
                      type="button"
                      className="button primary"
                      style={{ width: "100%" }}
                      onClick={handleSubmitX}
                      disabled={surveyItems.length > 0 ? true : false}
                    >
                      Guardar Encuesta
                    </button>
                  )}
                </div>
                {error && <span className="error">{error}</span>}
              </div>
            )}
            <div className="post-action">
              <div className="flex">
                <button
                  type="button"
                  className="button icon"
                  title={t("post.addPhotos")}
                  disabled={
                    watch("video" && "poster") || video || link || survey
                      ? true
                      : false
                  }
                >
                  <input
                    type="file"
                    accept="image/png, image/jpg, image/jpeg, image/gif, image/webp, image/svg"
                    {...register("photos")}
                    onChange={handleFileChange}
                    disabled={
                      watch("video" && "poster") || video || link || survey
                        ? true
                        : false
                    }
                    multiple
                    hidden
                    id="photos"
                  />
                  <label htmlFor="photos">
                    <MaterialSymbols icon="photo_library" size="24" />
                  </label>
                </button>
                <button
                  type="button"
                  className="button icon"
                  title="Añadir un vídeo de YouTube"
                  onClick={() => handleVideo()}
                  disabled={files?.length > 0 || link || survey ? true : false}
                  // disabled
                >
                  <FontAwesomeIcon icon={faYoutube} size="xl" />
                </button>
                <button
                  type="button"
                  className="button icon"
                  onClick={() => handleLink()}
                  title="Añadir un enlace"
                  // disabled={
                  //   files?.length > 0 ||
                  //   video ||
                  //   watch("video" && "poster") ||
                  //   poll
                  //     ? true
                  //     : false
                  // }
                  disabled
                >
                  <MaterialSymbols icon="link" size="24" rotate="-45" />
                </button>
                <button
                  type="button"
                  className="button icon"
                  title="Añadir una encuesta"
                  onClick={() => handleSurvey()}
                  // disabled={
                  //   watch("video" && "poster") ||
                  //   files?.length > 0 ||
                  //   video ||
                  //   link
                  //     ? true
                  //     : false
                  // }
                  // disabled
                >
                  <FontAwesomeIcon icon={faSquarePollHorizontal} size="xl" />
                </button>
              </div>
              <div className="flex">
                <button
                  type="submit"
                  className="button active"
                  // style={{}}
                  // disabled={watch("text") ? false : true}
                >
                  {t("post.post")}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Compose;
