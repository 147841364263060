import { collection, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";

export function useMessages(db) {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const messagesRef = collection(db, "messages");

    const unsubscribe = onSnapshot(
      messagesRef,
      (snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return { ...doc.data(), id: doc.id };
        });
        setMessages(data);
      },
      (error) => {
        console.error("Mensajes: Error al obtener los datos: ", error);
      }
    );

    return () => unsubscribe();
  }, [db]);

  return messages;
}
