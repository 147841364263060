import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useUsers } from "../hooks/useUsers";
import { usePosts } from "../hooks/usePosts";
import { db } from "../firebase/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faClose,
  faEllipsisV,
} from "@fortawesome/free-solid-svg-icons";
import { faImages } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import { MaterialSymbols } from "../common/MaterialSymbols";

function ProfilePhotos() {
  const users = useUsers(db);
  const posts = usePosts(db);
  const { t } = useTranslation();
  const { username } = useParams();
  const userSelected = users.find((user) => user.username === username);
  const postsFiltered = posts.filter(
    (post) => post.userId === userSelected?.id
  );
  const [glass, setGlass] = useState(false);
  const [photoSelected, setPhotoSelected] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const changePhoto = (photo) => {
    setPhotoSelected(photo);
    setGlass(true);
    setCurrentIndex(0);
  };

  const removePhoto = () => {
    setPhotoSelected(null);
    setGlass(false);
  };

  const nextPhoto = useCallback(() => {
    if (photoSelected) {
      setCurrentIndex(
        (prevIndex) => (prevIndex + 1) % photoSelected.photos.length
      );
    }
  }, [photoSelected]);

  const prevPhoto = useCallback(() => {
    if (photoSelected) {
      setCurrentIndex(
        (prevIndex) =>
          (prevIndex - 1 + photoSelected.photos.length) %
          photoSelected.photos.length
      );
    }
  }, [photoSelected]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "Escape") {
        removePhoto();
      } else if (event.code === "ArrowLeft" && photoSelected) {
        prevPhoto();
      } else if (event.code === "ArrowRight" && photoSelected) {
        nextPhoto();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [photoSelected, prevPhoto, nextPhoto]);

  return (
    <>
      {/* Renderiza las fotos del usuario */}
      {userSelected ? (
        <div className="profile-photos">
          {postsFiltered.length > 0 ? (
            postsFiltered
              .sort((a, b) => b.posted - a.posted)
              .map(
                (post, index) =>
                  post.show &&
                  (post.photos.length > 0 || post.video) && (
                    <div
                      key={index}
                      className="photo-box"
                      onClick={() => changePhoto(post)}
                    >
                      {post.photos?.length > 0 ? (
                        <img
                          src={post.photos[0]}
                          alt={post.alt ? post.alt : post.text}
                          loading="lazy"
                        />
                      ) : (
                        <img
                          src={post.poster}
                          alt={post.alt ? post.alt : post.title}
                          loading="lazy"
                        />
                      )}
                      {post.photos?.length > 1 && (
                        <FontAwesomeIcon icon={faImages} size="xl" />
                      )}
                      {post.video && (
                        <MaterialSymbols
                          icon="play_arrow"
                          size="34"
                          fill={true}
                        />
                      )}
                    </div>
                  )
              )
          ) : (
            <div className="code">Aún no hay fotos de este perfil.</div>
          )}
        </div>
      ) : null}
      {/* Modal para ver la foto seleccionada */}
      <div
        className={!glass ? "photo-page" : "photo-page show"}
        onClick={() => removePhoto()}
      ></div>
      {photoSelected && (
        <div className="profile-post">
          <button
            type="button"
            className="button close"
            title={t("button.close")}
            onClick={() => removePhoto()}
          >
            <FontAwesomeIcon icon={faClose} size="xl" />
          </button>
          <button type="button" className="button options" title="Opciones">
            <FontAwesomeIcon icon={faEllipsisV} size="xl" />
          </button>
          {photoSelected.photos.length > 1 && (
            <div className="counter">{`${currentIndex + 1}/${
              photoSelected.photos?.length
            }`}</div>
          )}
          <div className="profile-post-photos">
            {photoSelected.photos?.length > 1 && (
              <>
                <button
                  onClick={() => prevPhoto()}
                  className="prev-button"
                  title={t("button.prev")}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="xl" />
                </button>
                <button
                  onClick={() => nextPhoto()}
                  className="next-button"
                  title={t("button.next")}
                >
                  <FontAwesomeIcon icon={faArrowRight} size="xl" />
                </button>
              </>
            )}
            {photoSelected.photos && photoSelected.photos.length ? (
              <img
                src={photoSelected.photos[currentIndex]}
                alt={photoSelected.text}
                className="profile-post-photo-back"
                loading="lazy"
              />
            ) : (
              <img
                src={photoSelected.poster}
                alt={photoSelected.title}
                className="profile-post-photo-back"
                loading="lazy"
              />
            )}
            <div className="photo-item">
              {photoSelected.photos && photoSelected.photos.length > 0 && (
                <img
                  src={photoSelected.photos[currentIndex]}
                  alt={photoSelected.text}
                  className="profile-post-photo"
                  loading="lazy"
                />
              )}
              {photoSelected.video ? (
                <ReactPlayer
                  url={photoSelected.video}
                  light={photoSelected.poster}
                  controls={true}
                  playing={true}
                  loading="lazy"
                />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfilePhotos;
