import { useState } from "react";
import axios from "axios";
import { goBack } from "../utils/goBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";

function Debug() {
  const [url, setUrl] = useState("");
  const [metaData, setMetaData] = useState(null);

  const fetchMetaData = async () => {
    try {
      const response = await axios.get(`http://localhost:4000/debug`, {
        params: { url },
      });

      // Filtrar solo los metadatos relevantes
      const meta = response.data;
      setMetaData({
        image: meta["og:image"],
        title: meta["og:title"],
        description: meta["og:description"],
        url: meta["og:url"],
      });
    } catch (error) {
      console.error("Error fetching Open Graph data:", error);
    }
  };

  console.log(metaData);

  return (
    <>
      <div className="page">
        <div className="page-header">
          <button
            type="button"
            className="button icon"
            onClick={() => goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
          <div className="title">Open Graph</div>
          <div className="square"></div>
        </div>
        <div className="page-content">
          <div className="wrapper-field">
            <FontAwesomeIcon icon={faSearch} size="lg" />
            <input
              type="text"
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              placeholder="Introduce el enlace"
            />
            <button
              onClick={fetchMetaData}
              className="button"
              style={{
                height: "36px",
                border: "none",
                cursor: "pointer",
                borderRadius: "6px",
                backgroundColor: "var(--compose-color-primary)",
                font: "normal 11.5px var(--font-mono)",
                minWidth: "max-content",
                // letterSpacing: ".4px",
                padding: "0 20px",
              }}
            >
              {/* Obtener metadatos */}
              Buscar
            </button>
          </div>
          <div className="column">
            {metaData && (
              <>
                <div className="label">
                  <b>Vista previa:</b> pequeña, small.
                </div>
                <br />
                <a
                  href={metaData.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="card"
                >
                  {metaData.image && (
                    <div className="card-image">
                      <img src={metaData.image} alt="" />
                    </div>
                  )}
                  <div className="card-content">
                    {metaData.url && (
                      <div className="link">
                        {metaData.url
                          .replace(/https?:\/\//, "")
                          .replace(/\?.*$/, "")
                          .replace(/\/$/, "")}
                      </div>
                    )}
                    {metaData.title && (
                      <div className="title">{metaData.title}</div>
                    )}
                    {metaData.description && (
                      <div className="description">{metaData.description}</div>
                    )}
                  </div>
                </a>
              </>
            )}
            {metaData && (
              <>
                <br />
                <div className="label">
                  <b>Vista previa:</b> grande, large.
                </div>
                <br />
                <a
                  href={metaData.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="card-link"
                >
                  {metaData.image && (
                    <div className="card-link-image">
                      <img src={metaData.image} alt="" />
                    </div>
                  )}
                  <div className="card-link-content">
                    {metaData.url && (
                      <div className="link">
                        {metaData.url
                          .replace(/https?:\/\//, "")
                          .replace(/\?.*$/, "")
                          .replace(/\/$/, "")}
                      </div>
                    )}
                    {metaData.title && (
                      <div className="title">{metaData.title}</div>
                    )}
                    {metaData.description && (
                      <div className="description">{metaData.description}</div>
                    )}
                  </div>
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Debug;
