import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faFeather } from "@fortawesome/free-solid-svg-icons";
import Logo from "./Logo";
import NavMenu from "./NavMenu";
import MenuAlt from "./MenuAlt";
import { useTranslation } from "react-i18next";

function Menu(props) {
  const { t } = useTranslation();
  return (
    <>
      <div className="menu">
        <div className="wrapper">
          <div className="flex">
            <Logo />
            <NavMenu />
          </div>
          <div className="flex">
            <nav className="nav-menu">
              <ul className="nav-list">
                <li className="nav-item">
                  <button
                    type="button"
                    className="nav-link button compose"
                    onClick={() => props.handleCreate()}
                  >
                    <FontAwesomeIcon icon={faFeather} size="xl" />
                    <span className="nav-title">{t("menu.create")}</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <div className="flex">
            <nav className="nav-menu">
              <ul className="nav-list">
                <MenuAlt
                  menu={props.menu}
                  menuRef={props.menuRef}
                  langRef={props.langRef}
                  handleMenu={props.handleMenu}
                  handleLang={props.handleLang}
                />
                <li className="nav-item">
                  <button
                    type="button"
                    className="nav-link"
                    onClick={() => props.handleMenu()}
                  >
                    <FontAwesomeIcon icon={faBars} size="xl" />
                    <span className="nav-title">{t("menu.more")}</span>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}

export default Menu;
