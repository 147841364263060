import { formatDistanceToNowStrict } from "date-fns";
import { es, enUS } from "date-fns/locale";
import { MaterialSymbols } from "./MaterialSymbols";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as iconLoved } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { db } from "../firebase/config";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { abbrNumber } from "../utils/abbrNumber";
import Avatar from "./Avatar";

function Comment(props) {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const currentUser = users.find((user) => user.email === auth?.email);

  const formatDate = (timestamp, locale) => {
    if (!timestamp) return "";
    const date = new Date(timestamp.seconds * 1000);
    const dateLocale = locale === "es" ? es : enUS;
    const timeAgo = formatDistanceToNowStrict(date, {
      includeSeconds: true,
      addSuffix: true,
      locale: dateLocale,
    });
    return `${timeAgo}`;
  };

  const liked =
    Array.isArray(props.likes) && props.likes.includes(currentUser?.id);

  const authorPostLiked =
    Array.isArray(props.likes) && props.likes.includes(props.authorPostId);

  const author = props.userId === props.authorPostId;

  const [menu, setMenu] = useState(false);
  const menuRef = useRef(null);

  const handleMenu = () => {
    setMenu(!menu);
  };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenu(false);
    }
  };

  useEffect(() => {
    if (menu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menu]);

  const loved = Array.isArray(props.likes)
    ? props.likes.includes(props.userId)
    : false;

  const giveLove = async (postId, targetUserId, currentUserId) => {
    try {
      // Referencia al documento de la publicación
      const postRef = doc(db, "photos", postId);

      // Referencia a la colección de notificaciones
      const notificationsRef = collection(db, "notifications");

      const notification = notificationsRef.find(
        (notif) =>
          notif.targetUserId === targetUserId &&
          notif.currentUserId === currentUserId &&
          notif.type === "comment"
      );

      if (loved) {
        // Eliminar al usuario actual de los me gustas de la publicación
        await updateDoc(postRef, {
          likes: arrayRemove(currentUserId),
        });

        // Eliminar notificación de seguimiento
        if (notification?.id) {
          // Referencia al documento de la notificación
          const notificationRef = doc(db, "notifications", notification.id);
          await deleteDoc(notificationRef);
        }
      } else {
        // Agregar al usuario actual a los me gustas de la publicación
        await updateDoc(postRef, {
          likes: arrayUnion(currentUserId),
        });

        // Crear notificación de seguimiento
        await addDoc(notificationsRef, {
          targetUserId,
          currentUserId: currentUser.id,
          currentUserName: currentUser.name,
          currentUserUsername: currentUser.username,
          currentUserPicture: currentUser.picture,
          username: props.username,
          postId,
          type: "comment",
          time: serverTimestamp(),
        });
      }
    } catch (error) {
      console.error("Error al dar like: ", error);
    }
  };

  const copyComment = () => {
    setMenu(false);
    navigator.clipboard.writeText(
      `${props.name} (@${props.username}) comentó: ${props.text}`
    );
  };

  return (
    <div className="comment">
      <div className="comment-wrapper">
        <div className="comment-user">
          <Avatar {...props} />
        </div>
        <div className="comment-text">
          <div className="info">
            <div className="name">
              <Link to={`/${props.username}`} className="user-link">
                {props.name}
                {props.verified && (
                  <MaterialSymbols icon="verified" size="13" />
                )}
              </Link>
              {author && (
                <div className="author">
                  <MaterialSymbols icon="person" size="14" fill={true} />
                  {t("post.author")}
                </div>
              )}
              {false && (
                <div className="pin">
                  <MaterialSymbols icon="keep" size="12" />
                  {t("post.pinned_comment")}
                </div>
              )}
            </div>
            <div className="username">
              <Link to={`/${props.username}`} className="user-link">
                @{props.username}
              </Link>
              {" ~ "}
              {props.posted && formatDate(props.posted, t("code_lang"))}
            </div>
          </div>
          <div className="text">{props.text}</div>
          <div className="action">
            <button
              type="button"
              className="button reaction"
              onClick={() => giveLove()}
              disabled={!currentUser}
            >
              <div className={liked ? "icon red" : "icon"}>
                <MaterialSymbols
                  icon="favorite"
                  size="20"
                  fill={liked}
                />
              </div>
              {props.likes && props.likes.length > 0 && (
                <div className={liked ? "counter red" : "counter"}>
                  {abbrNumber(props.likes.length)}
                </div>
              )}
            </button>
            {authorPostLiked && (
              <div className="loved">
                <img
                  src={props.authorPostPicture}
                  alt={`${t("profile.picture")} ${props.authorPostName}`}
                  width={24}
                  height={24}
                />
                <FontAwesomeIcon icon={iconLoved} size="lg" />
              </div>
            )}
          </div>
        </div>
        <div className="comment-action">
          {/* Botón visible para el autor del comentario o el autor del post */}
          {currentUser &&
            (currentUser.id === props.userId ||
              currentUser.id === props.authorPostId) && (
              <button
                type="button"
                className="button icon"
                title={t("post.more")}
                onClick={() => handleMenu()}
              >
                <MaterialSymbols icon="more_horiz" size="20" />
              </button>
            )}
          <div ref={menuRef} className={!menu ? "post-menu" : "post-menu show"}>
            {/* Botones visibles para el autor del post */}
            {currentUser?.id === props.authorPostId && (
              <>
                <button type="button" className="post-menu-item" disabled>
                  <div className="wrapper">
                    <div className="icon">
                      <MaterialSymbols icon="keep" size="20" />
                    </div>
                    <div className="text">{t("post.pin_comment")}</div>
                  </div>
                </button>
                <button
                  type="button"
                  className="post-menu-item"
                  onClick={() => copyComment()}
                >
                  <div className="wrapper">
                    <div className="icon">
                      <MaterialSymbols icon="content_copy" size="20" />
                    </div>
                    <div className="text">Copiar comentario</div>
                  </div>
                </button>
                {/* <hr /> */}
              </>
            )}
            {/* Botones visibles solo para el autor del comentario */}
            {currentUser && currentUser?.id === props.userId && (
              <>
                <button type="button" className="post-menu-item" disabled>
                  <div className="wrapper">
                    <div className="icon alert">
                      <MaterialSymbols icon="edit_square" size="20" />
                    </div>
                    <div className="text">{t("post.edit_comment")}</div>
                  </div>
                </button>
              </>
            )}
            {/* Botones visibles para el autor del comentario y el autor del post */}
            {currentUser &&
              (currentUser?.id === props.userId ||
                currentUser?.id === props.authorPostId) && (
                <>
                  <button type="button" className="post-menu-item" disabled>
                    <div className="wrapper">
                      <div className="icon alert">
                        <MaterialSymbols icon="delete" size="20" />
                      </div>
                      <div className="text">{t("post.delete_comment")}</div>
                    </div>
                  </button>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Comment;
