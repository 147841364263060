import React from "react";
import { Link } from "react-router-dom";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";

// Expresiones regulares
const usernameRegex = /@\w+/g;
const hashtagRegex = /#\w+/g;

// Función que maneja @usuarios y #hashtags
export const formatText = (text) => {
  // Dividir el texto en partes (separadas por espacios)
//   const usernames = text.split(/(\s+|[^\w#]+)/);

  const parts = text.split(/(\s+|[^\w@]+[^\w#]+)/);
  //   split(/(\s+|[^\w#]+)/)

  return parts.map((part, index) => {
    // Si es un @usuario, convertirlo en un <Link> a la página del usuario
    if (usernameRegex.test(part)) {
      const users = useUsers(db);
      const username = part.slice(1).replace(/\./g, ""); // Quitar el '@'
      const userExists = users.some((user) => user.username === username);
      return (
        <>
          {userExists ? (
            <>
              <Link key={index} to={`/${username}`} className="username">
                {part}
              </Link>
            </>
          ) : (
            <>{part}</>
          )}
        </>
      );
    }

    // Si es un #hashtag, convertirlo en un <Link> al hashtag
    if (hashtagRegex.test(part)) {
      const hashtag = part.slice(1); // Quitar el '#'
      return (
        <>
          <Link key={index} to={`/hashtag/${hashtag}`} className="hashtag">
            {part}
          </Link>
        </>
      );
    }

    // Si no es un @usuario o #hashtag, devolver el texto normal
    return part;
  });
};
