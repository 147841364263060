import { Helmet } from "react-helmet-async";
import Post from "../common/Post";
import { db } from "../firebase/config";
import { useAuth } from "../hooks/useAuth";
import { usePosts } from "../hooks/usePosts";
import { useUsers } from "../hooks/useUsers";

function Favorites() {
  const auth = useAuth(db);
  const users = useUsers(db);
  const posts = usePosts(db);
  const currentUser = users.find((user) => user.email === auth?.email);

  const postsFiltered = posts.filter((post) =>
    currentUser.favorites.includes(post.userId)
  );

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`${window.location.origin}/favorites`} />
      </Helmet>
      {postsFiltered.map((post, index) => {
        // Buscar la información del autor de la publicación.
        const user = users.find((user) => user.id === post.userId);

        return (
          post.show && (
            <Post
              {...user}
              {...post}
              key={index}
              postId={post.id}
              authorId={user?.id}
            />
          )
        );
      })}
    </>
  );
}
export default Favorites;
