export const formatTime = (time, lang) => {
  const date = new Date(time.seconds * 1000).toLocaleDateString(lang, {
    weekday: "long",
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  return date;
};
