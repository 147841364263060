  // Abreviación de los números
  export const abbrNumber = (count) => {
    if (typeof count !== "number" || isNaN(count)) {
      return "???";
    }

    const units = ["", "K", "M", "B", "T", "P", "E", "Z", "Y", "GOL"];
    let unitIndex = 0;

    while (count >= 1000 && unitIndex < units.length - 1) {
      count /= 1000;
      unitIndex++;
    }

    return count.toFixed(1).replace(/\.0$/, "") + units[unitIndex];
  }