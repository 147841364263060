import { useParams } from "react-router-dom";
import { db } from "../firebase/config";
import { useForm } from "react-hook-form";
import { doc, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { goBack } from "../utils/goBack";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function ProfileEdit() {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { username } = useParams();
  const { register, handleSubmit, watch, setValue } = useForm();
  const currentUser = users.find((user) => user.email === auth?.email);

  useEffect(() => {
    if (currentUser) {
      setValue("name", currentUser?.name || "");
      setValue("username", currentUser?.username || "");
      setValue("bio", currentUser?.bio || "");
      setValue("website", currentUser?.website || "");
      setValue("spotify", currentUser?.spotify || "");
      setValue("youtube", currentUser?.youtube || "");
      setValue("gender", currentUser?.gender || "");
      setValue("birthdate", currentUser?.birthdate || "");
      setValue("country", currentUser?.country || "");
      setValue("email", currentUser?.email || "");
      setValue("id", currentUser?.id || "");
      setValue("uid", currentUser?.uid || "");
    }
  }, [currentUser, setValue]);

  if (!users || !auth) {
    return <p>Cargando...</p>;
  }

  if (!currentUser) {
    <p>No se pudo encontrar tu perfil</p>;
  }

  const bio = watch("bio");

  const onSubmit = async (data) => {
    try {
      const userDocRef = doc(db, "users", currentUser.id);
      await updateDoc(userDocRef, {
        name: data.name || currentUser.name,
        username: data.username || currentUser.username,
        bio: data.bio === "" ? "" : data.bio || currentUser.bio,
        website: data.website === "" ? "" : data.website || currentUser.website,
        spotify: data.spotify === "" ? "" : data.spotify || currentUser.spotify,
        youtube: data.youtube === "" ? "" : data.youtube || currentUser.youtube,
        gender: data.gender || currentUser.gender,
        birthdate: data.birthdate || currentUser.birthdate,
        country: data.country || currentUser.country,
      });
      alert("Perfil actualizado con éxito");
    } catch (error) {
      console.error("Error al actualizar el perfil:", error);
      alert("Hubo un error al actualizar el perfil");
    }
  };

  return (
    <>
      <Helmet>
        <link
          rel="canonical"
          href={`${window.location.origin}/${username}/edit`}
        />
      </Helmet>
      <div className="page">
        <div className="page-header">
          <button
            type="button"
            title="Volver atrás"
            className="button icon"
            onClick={() => goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
          <div className="title">Editar Perfil</div>
          <div className="square"></div>
        </div>
        {currentUser?.username === username ? (
          <div className="page-content">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="block">
                <label htmlFor="name">Nombre & Apellido:</label>
                <div className="wrapper-field">
                  <input
                    id="name"
                    type="text"
                    placeholder="Nombre"
                    {...register("name")}
                  />
                </div>
              </div>
              <div className="block">
                <label htmlFor="username">Nombre de usuario:</label>
                <div className="wrapper-field">
                  <span className="at">@</span>
                  <input
                    id="username"
                    type="text"
                    placeholder="username"
                    {...register("username")}
                    disabled
                  />
                </div>
              </div>
              <div className="block">
                <label htmlFor="bio">Descripción corta:</label>
                <div className="wrapper-field">
                  <textarea
                    id="bio"
                    rows={3}
                    placeholder="Descripción corta..."
                    {...register("bio", { minLength: 0, maxLength: 170 })}
                    minLength={0}
                    maxLength={170}
                  ></textarea>
                </div>
                {bio && <div className="counter">{`${bio.length}/170`}</div>}
              </div>
              <div className="block">
                <label htmlFor="website">Sitio web:</label>
                <div className="wrapper-field">
                  <input
                    id="website"
                    type="text"
                    placeholder={`https://${username}.com`}
                    {...register("website")}
                  />
                </div>
              </div>
              {currentUser.verified && (
                <div className="block">
                  <label htmlFor="spotify">Spotify:</label>
                  <div className="wrapper-field">
                    <input
                      id="spotify"
                      type="text"
                      placeholder={`https://open.spotify.com/artist/${username}`}
                      {...register("spotify")}
                    />
                  </div>
                </div>
              )}
              {currentUser.verified && (
                <div className="block">
                  <label htmlFor="youtube">Youtube:</label>
                  <div className="wrapper-field">
                    <input
                      id="youtube"
                      type="text"
                      placeholder={`https://youtube.com/@${username}`}
                      {...register("youtube")}
                    />
                  </div>
                </div>
              )}
              <div className="block hidden">
                <label htmlFor="gender">Género:</label>
                <div className="wrapper-field">
                  <select id="gender" {...register("gender")} defaultValue={""}>
                    <option value="">Seleccione su género</option>
                    <option value="male">Másculino</option>
                    <option value="female">Femenino</option>
                    <option value="other">Empresa</option>
                  </select>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </div>
              <div className="block hidden">
                <label htmlFor="birthdate">Edad:</label>
                <div className="wrapper-field">
                  <input
                    id="birthdate"
                    type="date"
                    placeholder={`edad`}
                    {...register("birthdate")}
                  />
                </div>
              </div>
              <div className="block hidden">
                <label htmlFor="country">País:</label>
                <div className="wrapper-field">
                  <select
                    {...register("country", {
                      required: true,
                    })}
                    autoComplete="off"
                    defaultValue="SV"
                  >
                    <option value="null" selected disabled>
                      Seleccione su país
                    </option>
                    <optgroup label="África">
                      <option value="DZ">Algeria</option>
                      <option value="AO">Angola</option>
                      <option value="BJ">Benin</option>
                      <option value="BW">Botswana</option>
                      <option value="BF">Burkina Faso</option>
                      <option value="BI">Burundi</option>
                      <option value="CV">Cape Verde</option>
                      <option value="CM">Cameroon</option>
                      <option value="CF">Central African Republic</option>
                      <option value="TD">Chad</option>
                      <option value="KM">Comoros</option>
                      <option value="CD">
                        Democratic Republic of the Congo
                      </option>
                      <option value="DJ">Djibouti</option>
                      <option value="EG">Egypt</option>
                      <option value="GQ">Equatorial Guinea</option>
                      <option value="ER">Eritrea</option>
                      <option value="SZ">Eswatini</option>
                      <option value="ET">Ethiopia</option>
                      <option value="GA">Gabon</option>
                      <option value="GM">Gambia</option>
                      <option value="GH">Ghana</option>
                      <option value="GN">Guinea</option>
                      <option value="GW">Guinea-Bissau</option>
                      <option value="CI">Ivory Coast</option>
                      <option value="KE">Kenya</option>
                      <option value="LS">Lesotho</option>
                      <option value="LR">Liberia</option>
                      <option value="LY">Libya</option>
                      <option value="MG">Madagascar</option>
                      <option value="MW">Malawi</option>
                      <option value="ML">Mali</option>
                      <option value="MR">Mauritania</option>
                      <option value="MU">Mauritius</option>
                      <option value="MA">Morocco</option>
                      <option value="MZ">Mozambique</option>
                      <option value="NA">Namibia</option>
                      <option value="NE">Niger</option>
                      <option value="NG">Nigeria</option>
                      <option value="CG">Republic of the Congo</option>
                      <option value="RW">Rwanda</option>
                      <option value="ST">São Tomé and Príncipe</option>
                      <option value="SN">Senegal</option>
                      <option value="SC">Seychelles</option>
                      <option value="SL">Sierra Leone</option>
                      <option value="SO">Somalia</option>
                      <option value="ZA">South Africa</option>
                      <option value="SS">South Sudan</option>
                      <option value="SD">Sudan</option>
                      <option value="TZ">Tanzania</option>
                      <option value="TG">Togo</option>
                      <option value="TN">Tunisia</option>
                      <option value="UG">Uganda</option>
                      <option value="ZM">Zambia</option>
                      <option value="ZW">Zimbabwe</option>
                    </optgroup>
                    <optgroup label="América">
                      <option value="AG">Antigua and Barbuda</option>
                      <option value="AR">Argentina</option>
                      <option value="BS">Bahamas</option>
                      <option value="BB">Barbados</option>
                      <option value="BZ">Belize</option>
                      <option value="BO">Bolivia</option>
                      <option value="BR">Brazil</option>
                      <option value="CA">Canada</option>
                      <option value="CL">Chile</option>
                      <option value="CO">Colombia</option>
                      <option value="CR">Costa Rica</option>
                      <option value="CU">Cuba</option>
                      <option value="DM">Dominica</option>
                      <option value="DO">Dominican Republic</option>
                      <option value="EC">Ecuador</option>
                      <option value="SV">El Salvador</option>
                      <option value="GD">Grenada</option>
                      <option value="GT">Guatemala</option>
                      <option value="GY">Guyana</option>
                      <option value="HT">Haiti</option>
                      <option value="HN">Honduras</option>
                      <option value="JM">Jamaica</option>
                      <option value="MX">Mexico</option>
                      <option value="NI">Nicaragua</option>
                      <option value="PA">Panama</option>
                      <option value="PY">Paraguay</option>
                      <option value="PE">Peru</option>
                      <option value="KN">Saint Kitts and Nevis</option>
                      <option value="LC">Saint Lucia</option>
                      <option value="VC">
                        Saint Vincent and the Grenadines
                      </option>
                      <option value="SR">Suriname</option>
                      <option value="TT">Trinidad and Tobago</option>
                      <option value="US">United States</option>
                      <option value="UY">Uruguay</option>
                      <option value="VE">Venezuela</option>
                    </optgroup>
                    <optgroup label="Asia">
                      <option value="AF">Afghanistan</option>
                      <option value="AM">Armenia</option>
                      <option value="AZ">Azerbaijan</option>
                      <option value="BH">Bahrain</option>
                      <option value="BD">Bangladesh</option>
                      <option value="BT">Bhutan</option>
                      <option value="BN">Brunei</option>
                      <option value="KH">Cambodia</option>
                      <option value="CN">China</option>
                      <option value="CY">Cyprus</option>
                      <option value="GE">Georgia</option>
                      <option value="IN">India</option>
                      <option value="ID">Indonesia</option>
                      <option value="IR">Iran</option>
                      <option value="IQ">Iraq</option>
                      <option value="IL">Israel</option>
                      <option value="JP">Japan</option>
                      <option value="JO">Jordan</option>
                      <option value="KZ">Kazakhstan</option>
                      <option value="KW">Kuwait</option>
                      <option value="KG">Kyrgyzstan</option>
                      <option value="LA">Laos</option>
                      <option value="LB">Lebanon</option>
                      <option value="MY">Malaysia</option>
                      <option value="MV">Maldives</option>
                      <option value="MN">Mongolia</option>
                      <option value="MM">Myanmar</option>
                      <option value="NP">Nepal</option>
                      <option value="KP">North Korea</option>
                      <option value="OM">Oman</option>
                      <option value="PK">Pakistan</option>
                      <option value="PS">Palestine</option>
                      <option value="PH">Philippines</option>
                      <option value="QA">Qatar</option>
                      <option value="SA">Saudi Arabia</option>
                      <option value="SG">Singapore</option>
                      <option value="KR">South Korea</option>
                      <option value="LK">Sri Lanka</option>
                      <option value="SY">Syria</option>
                      <option value="TW">Taiwan</option>
                      <option value="TJ">Tajikistan</option>
                      <option value="TH">Thailand</option>
                      <option value="TL">Timor-Leste</option>
                      <option value="TR">Turkey</option>
                      <option value="TM">Turkmenistan</option>
                      <option value="AE">United Arab Emirates</option>
                      <option value="UZ">Uzbekistan</option>
                      <option value="VN">Vietnam</option>
                      <option value="YE">Yemen</option>
                    </optgroup>
                    <optgroup label="Europa">
                      <option value="AL">Albania</option>
                      <option value="AD">Andorra</option>
                      <option value="AT">Austria</option>
                      <option value="BY">Belarus</option>
                      <option value="BE">Belgium</option>
                      <option value="BA">Bosnia and Herzegovina</option>
                      <option value="BG">Bulgaria</option>
                      <option value="HR">Croatia</option>
                      <option value="CY">Cyprus</option>
                      <option value="CZ">Czech Republic</option>
                      <option value="DK">Denmark</option>
                      <option value="EE">Estonia</option>
                      <option value="FI">Finland</option>
                      <option value="FR">France</option>
                      <option value="GE">Georgia</option>
                      <option value="DE">Germany</option>
                      <option value="GR">Greece</option>
                      <option value="HU">Hungary</option>
                      <option value="IS">Iceland</option>
                      <option value="IE">Ireland</option>
                      <option value="IT">Italy</option>
                      <option value="XK">Kosovo</option>
                      <option value="LV">Latvia</option>
                      <option value="LI">Liechtenstein</option>
                      <option value="LT">Lithuania</option>
                      <option value="LU">Luxembourg</option>
                      <option value="MT">Malta</option>
                      <option value="MD">Moldova</option>
                      <option value="MC">Monaco</option>
                      <option value="ME">Montenegro</option>
                      <option value="NL">Netherlands</option>
                      <option value="MK">North Macedonia</option>
                      <option value="NO">Norway</option>
                      <option value="PL">Poland</option>
                      <option value="PT">Portugal</option>
                      <option value="RO">Romania</option>
                      <option value="RU">Russia</option>
                      <option value="SM">San Marino</option>
                      <option value="RS">Serbia</option>
                      <option value="SK">Slovakia</option>
                      <option value="SI">Slovenia</option>
                      <option value="ES">Spain</option>
                      <option value="SE">Sweden</option>
                      <option value="CH">Switzerland</option>
                      <option value="UA">Ukraine</option>
                      <option value="GB">United Kingdom</option>
                      <option value="VA">Vatican City</option>
                    </optgroup>
                    <optgroup label="Oceanía">
                      <option value="AU">Australia</option>
                      <option value="FJ">Fiji</option>
                      <option value="KI">Kiribati</option>
                      <option value="MH">Marshall Islands</option>
                      <option value="FM">Micronesia</option>
                      <option value="NR">Nauru</option>
                      <option value="NZ">New Zealand</option>
                      <option value="PW">Palau</option>
                      <option value="PG">Papua New Guinea</option>
                      <option value="WS">Samoa</option>
                      <option value="SB">Solomon Islands</option>
                      <option value="TO">Tonga</option>
                      <option value="TV">Tuvalu</option>
                      <option value="VU">Vanuatu</option>
                    </optgroup>
                  </select>
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
              </div>
              <div className="block">
                <label htmlFor="id">Correo electrónico:</label>
                <div className="wrapper-field">
                  <input type="text" id="id" {...register("email")} disabled />
                </div>
              </div>
              <div className="block">
                <label htmlFor="id">ID de usuario:</label>
                <div className="wrapper-field">
                  <input type="text" id="id" {...register("id")} disabled />
                </div>
              </div>
              <div className="block hidden">
                <label htmlFor="uid">UID de usuario:</label>
                <div className="wrapper-field">
                  <input type="text" id="uid" {...register("uid")} disabled />
                </div>
              </div>
              <br />
              <div className="block row">
                <button type="submit" className="button submit">
                  Guardar cambios
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div className="page-content">
            <div className="code">No tienes acceso a está página.</div>
          </div>
        )}
      </div>
    </>
  );
}

export default ProfileEdit;
