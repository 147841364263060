import { db, storage } from "../firebase/config";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { v4 } from "uuid";
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { abbrNumber } from "../config/defult";
import { MaterialSymbols } from "./MaterialSymbols";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { useNotifications } from "../hooks/useNotifications";
import Avatar from "./Avatar";
import { formatText } from "../utils/formatText";
import { usePosts } from "../hooks/usePosts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpotify, faYoutube } from "@fortawesome/free-brands-svg-icons";

function ProfileHeader(props) {
  const auth = useAuth(db);
  const users = useUsers(db);
  const posts = usePosts(db);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { username } = useParams();
  const notifications = useNotifications(db);
  const currentUser = users.find((user) => user.email === auth?.email);

  // const { username } = useParams();
  const userSelected = users.find((user) => user.username === props.username);
  const postsFiltered = posts.filter(
    (post) => post.userId === userSelected?.id && post.show === true
  );

  const uploadPicture = async (file) => {
    const userId = currentUser.id;
    const username = currentUser.username;
    console.log("ID del usuario: ", userId, username);

    const storageRef = ref(storage, `users/${username}/profile/${v4()}`);
    await uploadBytes(storageRef, file);
    const url = await getDownloadURL(storageRef);
    const userDoc = doc(db, "users", currentUser.id);
    await setDoc(userDoc, { picture: url }, { merge: true });
    return url;
  };

  const [picture, setPicture] = useState(false);
  const picRef = useRef(null);

  const changePicture = () => {
    setPicture(!picture);
  };

  const handleClickOutside = (event) => {
    if (picRef.current && !picRef.current.contains(event.target)) {
      setPicture(false);
    }
  };

  useEffect(() => {
    if (picture) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [picture]);

  const [withOutPicture, setWithOutPicture] = useState(false);

  const removePicture = () => {
    setWithOutPicture(!withOutPicture);
  };

  const [file, setFile] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const result = await uploadPicture(file);
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    uploadPicture(file);
    setPicture(!picture);
  };

  // followers
  const userId = currentUser?.id || null;

  const followed = Array.isArray(props.followers)
    ? props.followers.includes(userId)
    : false;

  const follow = async (targetUserId, currentUserId) => {
    try {
      // Referencia al documento del usuario objetivo
      const targetUserRef = doc(db, "users", targetUserId);

      // Referencia al documento del usuario actual
      const currentUserRef = doc(db, "users", currentUserId);

      // Referencia a la colección de notificaciones
      const notificationsRef = collection(db, "notifications");

      const notification = notifications.find(
        (notif) =>
          notif.targetUserId === targetUserId &&
          notif.currentUserId === currentUserId &&
          notif.type === "follow"
      );

      if (followed) {
        // Eliminar al usuario actual de los seguidores
        await updateDoc(targetUserRef, {
          followers: arrayRemove(currentUserId),
        });

        // Eliminar al usuario objetivo de los seguidores
        await updateDoc(currentUserRef, {
          following: arrayRemove(targetUserId),
        });

        // Eliminar notificación de seguimiento
        if (notification?.id) {
          // Referencia al documento de la notificación
          const notificationRef = doc(db, "notifications", notification.id);

          await deleteDoc(notificationRef);
        }
      } else {
        // Agregar al usuario actual a los seguidores
        await updateDoc(targetUserRef, {
          followers: arrayUnion(currentUserId),
        });

        // Agregar al usuario objetivos a los seguidores
        await updateDoc(currentUserRef, {
          following: arrayUnion(targetUserId),
        });

        // Crear notificación de seguimiento
        await addDoc(notificationsRef, {
          targetUserId,
          currentUserId: currentUser.id,
          currentUserName: currentUser.name,
          currentUserUsername: currentUser.username,
          currentUserPicture: currentUser.picture,
          type: "follow",
          time: serverTimestamp(),
        });
      }
    } catch (error) {
      console.error("Error al actualizar los seguidores: ", error);
    }
  };

  const formatLink = (link) => {
    return link
      .replace(/^https?:\/\//, "")
      .replace(/\?.*$/, "")
      .replace(/\/$/, "");
  };

  const goToEditProfile = () => {
    navigate(`/${currentUser.username}/edit`);
  };

  const goToArchive = () => {
    navigate(`/${currentUser.username}/archive`);
  };

  const goToStats = () => {
    navigate(`/${currentUser.username}/stats`);
  };

  const goToChat = () => {
    navigate(`/messages/${username}/${v4()}`);
  };

  const followers = Array.isArray(currentUser?.followers)
    ? currentUser?.followers.includes(props?.id)
    : false;

  return (
    <>
      {currentUser ? (
        <>
          <div className="profile-header-details">
            <div className="profile-picture">
              {currentUser.username === props.username ? (
                <button
                  type="button"
                  className="button-avatar"
                  title={t("profile.change_picture")}
                  onClick={() => changePicture()}
                >
                  <Avatar {...currentUser} width="138" height="138" />
                  <MaterialSymbols
                    icon="photo_camera"
                    size="30"
                    className=" camera"
                  />
                </button>
              ) : (
                <Avatar {...props} width="138" height="138" />
              )}
            </div>
            <div className="profile-data">
              {/* Nombre visible */}
              <div className="profile-row" style={{ gap: "2px" }}>
                <div className="name">{props.name}</div>
                {props.verified && (
                  <div className="badge" title={t("profile.verified")}>
                    <MaterialSymbols icon="verified" size="20" />
                  </div>
                )}
              </div>
              {/* Ususario, Seguidores, Seguidos, Publicaciones */}
              <div className="profile-row">
                <div className="username">{`@${props.username}`}</div>
                {props.followers && (
                  <Link
                    to={`/${props.username}/followers`}
                    className="followers"
                  >
                    <div className="counter">
                      {abbrNumber(props.followers?.length)}
                    </div>
                    <div className="text">
                      {props.followers === 1
                        ? t("profile.follower")
                        : t("profile.followers")}
                    </div>
                  </Link>
                )}
                {props.following && (
                  <Link
                    to={`/${props.username}/following`}
                    className="following"
                  >
                    <div className="counter">
                      {abbrNumber(props.following?.length)}
                    </div>
                    <div className="text">
                      {props.following === 1
                        ? t("profile.following")
                        : t("profile.following")}
                    </div>
                  </Link>
                )}
                {postsFiltered && (
                  <div className="posts">
                    <div className="counter">
                      {abbrNumber(postsFiltered.length)}
                    </div>
                    <div className="text">
                      {postsFiltered.length === 1
                        ? t("profile.post")
                        : t("profile.posts")}
                    </div>
                  </div>
                )}
              </div>
              {/* Descripción corta */}
              <div className="profile-row">
                <h2 className="bio">
                  {props.bio ? formatText(props.bio) : t("profile.description")}
                </h2>
              </div>
              {/* Sitio web, enlaces */}
              {props.website && (
                <div className="profile-row">
                  <a
                    href={props.website}
                    target="_blank"
                    className="website"
                    rel="noopener noreferrer"
                  >
                    <MaterialSymbols icon="link" size="14" rotate="-45" />{" "}
                    <div className="text">{formatLink(props.website)}</div>
                  </a>
                </div>
              )}
              {/* Botones */}
              <div className="profile-row">
                {currentUser?.username === props.username ? (
                  <>
                    <button
                      type="button"
                      onClick={() => goToEditProfile()}
                      className={"button follow"}
                    >
                      {t("profile.edit")}
                    </button>
                    <button
                      type="button"
                      onClick={() => goToArchive()}
                      className={"button follow"}
                    >
                      {t("profile.archive")}
                    </button>
                    <button
                      type="button"
                      onClick={() => goToStats()}
                      className={"button follow"}
                      disabled={
                        currentUser.followers.length > 100 ? false : true
                      }
                    >
                      {t("profile.stats")}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      onClick={() => follow(props.id, userId)}
                      className={
                        !followed ? "button follow" : "button followed"
                      }
                    >
                      {!followed
                        ? followers
                          ? t("profile.follow_back")
                          : t("profile.follow")
                        : t("profile.following")}
                    </button>
                    <button
                      type="button"
                      className="button follow"
                      onClick={() => goToChat()}
                      disabled
                    >
                      Enviar mensaje
                    </button>
                  </>
                )}
                {userSelected.spotify && (
                  <button
                    type="button"
                    title={`Ir al perfil de @${userSelected.username} en Spotify`}
                    className="button follow"
                    style={{ padding: "0 8px" }}
                    onClick={() => window.open(userSelected.spotify, "_blank")}
                  >
                    <FontAwesomeIcon icon={faSpotify} size="xl" />
                  </button>
                )}
                {userSelected.youtube && (
                  <button
                    type="button"
                    title={`Ir al canal de @${userSelected.username} en YouTube`}
                    className="button follow"
                    style={{ padding: "0 8px" }}
                    onClick={() => window.open(userSelected.youtube, "_blank")}
                  >
                    <FontAwesomeIcon icon={faYoutube} size="xl" />
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* Menú de la foto de perfil */}
          <div
            ref={picRef}
            className={!picture ? "menu-picture" : "menu-picture show"}
          >
            <form onSubmit={handleSubmit}>
              <input type="file" id="pic" onChange={handleFileChange} hidden />
              <button type="button" id="btnUpload">
                <label htmlFor="pic">{t("profile.upload")}</label>
              </button>
              {props.picture && (
                <button
                  type="reset"
                  id="btnDelete"
                  onClick={() => removePicture()}
                >
                  {t("profile.delete")}
                </button>
              )}
              <button type="button" onClick={() => changePicture()}>
                {t("button.cancel")}
              </button>
            </form>
          </div>
        </>
      ) : null}
    </>
  );
}

export default ProfileHeader;
