import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCircleInfo,
  faCloudArrowDown,
} from "@fortawesome/free-solid-svg-icons";
import { abbrNumber } from "../utils/abbrNumber";
import { goBack } from "../utils/goBack";
import { useEffect, useState } from "react";

function ProfileStats() {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const { username } = useParams();
  const currentUser = users.find((user) => user.email === auth?.email);
  const [genderStats, setGenderStats] = useState({
    male: 0,
    female: 0,
    other: 0,
  });
  const [countryStats, setCountryStats] = useState({});
  const [languageStats, setLanguageStats] = useState({});
  const [ageStats, setAgeStats] = useState({});

  useEffect(() => {
    if (currentUser) {
      const followers = currentUser?.followers?.map((id) =>
        users.find((user) => user.id === id)
      );

      const genderStats = { male: 0, female: 0, other: 0 };
      const countryStats = {};
      const languageStats = {};
      const ageStats = {};

      followers.forEach((follower) => {
        // Género
        if (follower.gender === "male") genderStats.male++;
        else if (follower.gender === "female") genderStats.female++;
        else genderStats.other++;

        // País
        if (countryStats[follower.country]) countryStats[follower.country]++;
        else countryStats[follower.country] = 1;

        // Idioma
        if (languageStats[follower.lang]) languageStats[follower.lang]++;
        else languageStats[follower.lang] = 1;

        // Edad
        const birthdate = new Date(follower.birthdate);
        const today = new Date();
        let age = today.getFullYear() - birthdate.getFullYear();
        const monthDifference = today.getMonth() - birthdate.getMonth();
        const dayDifference = today.getDate() - birthdate.getDate();
        if (
          monthDifference < 0 ||
          (monthDifference === 0 && dayDifference < 0)
        ) {
          age--;
        }
        if (ageStats[age]) ageStats[age]++;
        else ageStats[age] = 1;
      });

      setGenderStats(genderStats);
      setCountryStats(countryStats);
      setLanguageStats(languageStats);
      setAgeStats(ageStats);
    }
  }, [currentUser, users]);

  const followers = 100;

  return (
    <>
      <div className="page">
        <div className="page-header">
          <button
            type="button"
            title="Volver atrás"
            className="button icon"
            onClick={() => goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
          <div className="title">{t("profile.stats")}</div>
          <button
            type="button"
            title="Descargar"
            className="button icon"
            disabled
          >
            <FontAwesomeIcon icon={faCloudArrowDown} size="lg" />
          </button>
        </div>
        {currentUser?.username === username ? (
          <div className="page-content">
            {currentUser?.followers?.length >= followers ? (
              <div className="block">
                <label>Audiencia</label>
                <div className="item">
                  <div className="text">Seguidores</div>
                  <div className="counter">
                    {abbrNumber(currentUser.followers?.length)}
                  </div>
                </div>
                <div className="item">
                  <div className="text">Visualizaciones</div>
                  <div className="counter">{0}</div>
                </div>
                <label>Género</label>
                <div className="item">
                  <div className="text">Másculinos</div>
                  <div className="counter">{abbrNumber(genderStats.male)}</div>
                </div>
                <div className="item">
                  <div className="text">Femeninos</div>
                  <div className="counter">
                    {abbrNumber(genderStats.female)}
                  </div>
                </div>
                <label>País</label>
                {Object.entries(countryStats)
                  .sort((a, b) => b - a)
                  .map(([country, count]) => (
                    <div className="item" key={country}>
                      <div className="text">{country}</div>
                      <div className="counter">{abbrNumber(count)}</div>
                    </div>
                  ))}
                <label>Idioma</label>
                {Object.entries(languageStats).map(([lang, count]) => (
                  <div className="item" key={lang}>
                    <div className="text">{lang}</div>
                    <div className="counter">{abbrNumber(count)}</div>
                  </div>
                ))}
                <label>Edad</label>
                {Object.entries(ageStats).map(([age, count]) => (
                  <div className="item" key={age}>
                    <div className="text">{age} años</div>
                    <div className="counter">{abbrNumber(count)}</div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  gap: "10px",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <FontAwesomeIcon icon={faCircleInfo} size="2x" />
                <div
                  style={{
                    textWrap: "balance",
                    textAlign: "center",
                    width: "calc(100% - 40%)",
                    font: "normal 13.5px / 1.5 var(--font-display)",
                  }}
                >
                  Podrás obtener más información sobre las estadísticas cuando
                  tengas al menos
                  {" " + followers + " "}
                  seguidores.
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="page-content">
            <div className="code">No tienes acceso a está página.</div>
          </div>
        )}
      </div>
    </>
  );
}

export default ProfileStats;
