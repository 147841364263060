import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { website_name } from "../config/app";
import { goBack } from "../utils/goBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faSearch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { usePosts } from "../hooks/usePosts";
import { db } from "../firebase/config";
import Post from "../common/Post";
import { useState } from "react";
import { useUsers } from "../hooks/useUsers";

function Hashtag() {
  const posts = usePosts(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hashtag } = useParams();
  const [searchTerm, setSearchTerm] = useState(hashtag ? `#${hashtag}` : "#");

  // Función para manejar cambios en el input de búsqueda
  const handleSearchChange = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);
  };

  // Función para manejar Enter
  const handleSearchKeyDown = (event) => {
    if (event.key === "Enter") {
      // Actualiza la URL con el nuevo hashtag (sin # al inicio)
      if (searchTerm.startsWith("#")) {
        navigate(`/search/hashtag/${searchTerm.slice(1)}`);
      }
    }
  };

  const filteredPosts = posts.filter((post) =>
    post.text && post.text.toLowerCase().includes(`#${hashtag?.toLowerCase()}`)
  );

  return (
    <>
      <Helmet>
        <title>
          {t("title.search")} ~ {website_name}
        </title>
        <link rel="canonical" href={`${window.location.origin}/search/hashtag/${hashtag}`} />
        <meta name="og:url" content={`${window.location.origin}/search/hashtag/${hashtag}`} />
        <meta property="twitter:url" content={`${window.location.origin}/search/hashtag/${hashtag}`} />
      </Helmet>
      <div className="page">
        <div className="page-header">
          <button
            type="button"
            className="button icon"
            onClick={() => goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
          <div className="title">{t("title.search")}</div>
          {/* <div className="square"></div> */}
          <button
            type="button"
            className="button icon"
            title="Resultados"
            disabled
          >
            <div className="counter">{filteredPosts.length}</div>
          </button>
        </div>
        <div className="page-content">
          <div className="wrapper-field">
            <input
              type="text"
              placeholder="Buscar publicaciones por #hashtag"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyDown={handleSearchKeyDown}
            />
            <FontAwesomeIcon icon={faSearch} size="lg" className="search" />
          </div>
          <div className="column" style={{ gap: "20px" }}>
            {filteredPosts?.length > 0 ? (
              filteredPosts
                .sort((a, b) => b.posted - a.posted)
                .map((post, index) => {
                  // Buscar la información del autor de la publicación.
                  const user = users.find((user) => user.id === post.userId);

                  return (
                    post.show && (
                      <Post
                        {...post}
                        {...user}
                        key={index}
                        postId={post.id}
                        authorId={user?.id}
                      />
                    )
                  );
                })
            ) : (
              <div className="empty">
                <p>
                  {hashtag
                    ? `Aún no hay publicaciones con el #${hashtag}.`
                    : "Ingresa un hashtag en la barra de búsqueda y presiona 'Enter' para ver publicaciones relacionadas."}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Hashtag;
