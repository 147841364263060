import { useParams } from "react-router-dom";
import { db } from "../firebase/config";

import { useUsers } from "../hooks/useUsers";
import { usePosts } from "../hooks/usePosts";

import PostHeader from "../common/PostHeader";
import PostContent from "../common/PostContent";
import PostAction from "../common/PostAction";
import PostComments from "../common/PostComments";
import PostMeta from "../common/PostMeta";
import PostCommentInput from "../common/PostCommentInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { goBack } from "../utils/goBack";
import { Helmet } from "react-helmet-async";

function PostPage() {
  const users = useUsers(db);
  const posts = usePosts(db);
  const { username, postId } = useParams();
  const user = users.find((user) => user.username === username);
  const postSelected = posts.find((post) => post.id === postId);
  const author = users.find((user) => user.id === postSelected?.userId);

  return (
    <div className="page">
      <Helmet>
        <link rel="canonical" href={`${window.location.origin}/${username}/post/${postId}`} />
        <meta name="og:url" content={`${window.location.origin}/${username}/post/${postId}`} />
        <meta property="twitter:url" content={`${window.location.origin}/${username}/post/${postId}`} />
      </Helmet>
      <div className="page-header">
        <button
          type="button"
          title="Volver atrás"
          className="button icon"
          onClick={() => goBack()}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </button>
        <div className="title">Publicación</div>
        <div className="square"></div>
      </div>
      <div
        className="page-content"
        style={{
          padding: "0",
          width: "100%",
          borderColor: "transparent",
          backgroundColor: "transparent",
        }}
      >
        {postSelected && postSelected.show ? (
          <div className="post">
            <PostHeader
              {...user}
              postId={postId}
              {...postSelected}
              authorId={author.id}
            />
            <PostContent {...postSelected} />
            <PostMeta {...postSelected} />
            <PostAction {...postSelected} {...user} postId={postId} />
            <PostCommentInput {...postSelected} />
            <PostComments {...postSelected} {...user} />
          </div>
        ) : (
          <div className="post">
            <div className="error">
              Esta publicación no existe o ya no esta disponible.
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default PostPage;
