import { Link, useParams } from "react-router-dom";
import { goBack } from "../utils/goBack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPaperclip,
  faPaperPlane,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { useMessages } from "../hooks/useMessages";
import { db } from "../firebase/config";
import { useForm } from "react-hook-form";
import { MaterialSymbols } from "../common/MaterialSymbols";
import { formatLink } from "../utils/formatLink";
import Avatar from "../common/Avatar";
import { abbrNumber } from "../utils/abbrNumber";
import { useTranslation } from "react-i18next";
import { serverTimestamp } from "firebase/firestore";

function Messaje() {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const messages = useMessages(db);
  const { username, id } = useParams();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const currentUser = users.find((user) => user.email === auth?.email);

  const user = users.find((user) => user.username === username);
  const chat = messages.find((doc) => doc.id === id);

  const onSubmit = (data) => {
    const message = {
      id: currentUser.id,
      text: data.text,
      photos: [],
      sent: serverTimestamp(),
    };
    console.log(message);
  };

  return (
    <div className="page">
      <div className="page-header">
        <button
          type="button"
          title="Volver atrás"
          className="button icon"
          onClick={() => goBack()}
        >
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
        </button>
        <div className="title">@{username}</div>
        <div className="square"></div>
      </div>
      <div
        className="page-content"
        style={{
          gap: 0,
          padding: 0,
          width: "100%",
          height: "82vh",
        }}
      >
        <div className="content">
          {user && (
            <div className="block">
              <div className="user-info">
                <Avatar {...user} width={100} height={100} />
                <div className="name">{user.name}</div>
                <div className="username">@{username}</div>
                <div className="followers">
                  {abbrNumber(user.followers.length)}{" "}
                  {user.followers.length > 0 ? "Seguidores" : "Seguidor"}
                </div>
                <Link to={`/${username}`} className="button primary">
                  ver perfil
                </Link>
              </div>
            </div>
          )}
          {chat?.messages
            .sort((a, b) => a.sent - b.sent)
            .map((message, index) => (
              <div
                key={index}
                className={`block ${
                  message.id === currentUser?.id ? "sender" : "receiver"
                }`}
              >
                <div className="bubble">
                  {message.text && (
                    <div
                      className="text"
                      dangerouslySetInnerHTML={{
                        __html: formatLink(message.text),
                      }}
                    ></div>
                  )}
                  {message.photos && (
                    <div
                      className={`photo${
                        message.photos.length >= 4 ? "s" : ""
                      }`}
                    >
                      {message.photos
                        .sort((a, b) => a - b)
                        .slice(0, 4)
                        .map((photo, index) => (
                          <div key={index} className="photo-box">
                            <img
                              // key={index}
                              src={photo}
                              alt=""
                              // width={300}
                              // height={300}
                              style={{
                                maxWidth: "300px",
                                maxHeight: "300px",
                                borderRadius: "8px",
                              }}
                            />
                            {message.photos.length > 4 && (
                              <div className="more">
                                <FontAwesomeIcon icon={faPlus} size="lg" />
                                {message.photos.length > 4 &&
                                  message.photos.length - 4}
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  )}
                  {message.sent && (
                    <div className="sent">
                      {new Date(message.sent.seconds * 1000).toLocaleDateString(
                        t("code_lang"),
                        {
                          weekday: "short",
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                          // second: "2-digit",
                        }
                      )}
                      <MaterialSymbols
                        icon="done_all"
                        size="14"
                        color="var(--compose-primary-color)"
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="bar">
          <div className="row">
            <button type="button" className="button icon">
              <FontAwesomeIcon icon={faPaperclip} size="lg" />
            </button>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              <textarea
                placeholder="Escribe un mensaje."
                {...register("text", {
                  required: true,
                  pattern: {
                    // value: /^[a-zA-Z0-9\s.:,;'¡!¿?áéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ+*-#@]*$/,
                    value: /^[^<]*$/,
                    message: "Los caracteres '<' y '>' no están permitidos.",
                  },
                  validate: (value) => value.trim() !== "",
                })}
                className={`textarea ${
                  errors.text && errors.text.message ? "error" : ""
                }`}
              ></textarea>
              <button
                type="submit"
                title="Enviar"
                className="button icon"
                style={{ backgroundColor: "var(--compose-primary-color)" }}
              >
                <FontAwesomeIcon icon={faPaperPlane} size="lg" />
              </button>
            </form>
          </div>
          {errors.text && errors.text.message && (
            <div className="row">
              <div className="error-message">{errors.text.message}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default Messaje;
