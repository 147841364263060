import PostHeader from "./PostHeader";
import PostContent from "./PostContent";
import PostAction from "./PostAction";
import PostCommentInput from "./PostCommentInput";

function Post(props) {
  return (
    <div className="post">
      <PostHeader {...props} authorId={props.authorId} />
      <PostContent {...props} />
      <PostAction {...props} />
      <PostCommentInput {...props} />
    </div>
  );
}

export default Post;
