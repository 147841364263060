import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { website_name } from "../config/app";
import { useNotifications } from "../hooks/useNotifications";
import { db } from "../firebase/config";

import ItemNotify from "../common/ItemNotify";
import { useUsers } from "../hooks/useUsers";
import { usePosts } from "../hooks/usePosts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faGear } from "@fortawesome/free-solid-svg-icons";
import { goBack } from "../utils/goBack";
import { MaterialSymbols } from "../common/MaterialSymbols";
import { useState } from "react";

function Notifications() {
  const users = useUsers(db);
  const posts = usePosts(db);
  const { t } = useTranslation();
  const notifications = useNotifications(db);
  const [filterType, setFilterType] = useState("all");

  const filteredNotifications = notifications.filter((notification) => {
    // const isRecent = (new Date() - new Date(notification.time)) / (1000 * 60 * 60 * 24);
    if (filterType === "all") return true;
    return (
      notification.type === filterType
      // && isRecent
    );
  });

  return (
    <>
      <Helmet>
        <title>{`${t("title.notifications")} ~ ${website_name}`}</title>
      </Helmet>
      <div className="page">
        <div className="page-header">
          <button
            type="button"
            className="button icon"
            onClick={() => goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          </button>
          <div className="title">{t("title.notifications")}</div>
          <button type="button" className="button icon" disabled>
            {false ? (
              <FontAwesomeIcon icon={faGear} size="lg" />
            ) : (
              <MaterialSymbols icon="settings" size="18" />
            )}
          </button>
        </div>
        <div className="page-content">
          <div className="wrapper-field">
            <button
              type="button"
              className={`button primary ${
                filterType === "all" ? "active" : ""
              }`}
              title="Todas"
              onClick={() => setFilterType("all")}
            >
              Todas
            </button>
            <button
              type="button"
              className={`button primary ${
                filterType === "follow" ? "active" : ""
              }`}
              title={t("notifications.button.follow")}
              onClick={() => setFilterType("follow")}
            >
              {t("notifications.button.follow")}
            </button>
            <button
              type="button"
              className={`button primary ${
                filterType === "like" ? "active" : ""
              }`}
              title={t("notifications.button.like")}
              onClick={() => setFilterType("like")}
            >
              {t("notifications.button.like")}
            </button>
            <button
              type="button"
              className={`button primary ${
                filterType === "comment" ? "active" : ""
              }`}
              title={t("notifications.button.comments")}
              onClick={() => setFilterType("comment")}
            >
              {t("notifications.button.comments")}
            </button>
          </div>
          <div className="column">
            {filteredNotifications.length > 0 ? (
              filteredNotifications
                .sort((a, b) => b.time - a.time)
                .map((notification, index) => {
                  // Información del autor.
                  const user = users.find(
                    (user) => user.id === notification.currentUserId
                  );
                  // Información de la publicación.
                  const post = posts.find(
                    (post) => post.id === notification.postId
                  );

                  return (
                    <ItemNotify
                      key={index}
                      {...user}
                      {...post}
                      {...notification}
                    />
                  );
                })
            ) : (
              <div className="empty">
                <p>Aún no hay notificaciones que mostrar.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Notifications;
