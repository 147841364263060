import "./styles/app.css";
import { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";
import { db } from "./firebase/config";

import Register from "./pages/Register";
import Login from "./pages/Login";

import MainLayout from "./layouts/MainLayout";
import Home from "./pages/Home";
import Feed from "./layouts/FeedLayout";
import Following from "./pages/Following";
import Mutual from "./pages/Mutual";
import Favorites from "./pages/Favorites";
import Search from "./pages/Search";
import Hashtag from "./pages/Hashtag";
import Notifications from "./pages/Notifications";
import Compose from "./pages/Compose";
import Messages from "./pages/Messages";
import Message from "./pages/Message";

import PostPage from "./pages/PostPage";

import ProfileLayout from "./layouts/ProfileLayout";
import ProfileHome from "./pages/ProfileHome";
import ProfilePhotos from "./pages/ProfilePhotos";
import ProfileVideos from "./pages/ProfileVideos";
import ProfileMusic from "./pages/ProfileMusic";
import ProfileSaved from "./pages/ProfileSaved";
import ProfileEdit from "./pages/ProfileEdit";
import ProfileArchive from "./pages/ProfileArchive";
import ProfileStats from "./pages/ProfileStats";
import Followers from "./pages/Followers";

import Comps from "./pages/Comps";
import Loader from "./common/Loader";

import UsersPage from "./pages/UsersPage";
import Debug from "./pages/Debug";
import Followings from "./pages/Followings";
import Settings from "./pages/Settings";
import ProfileAbout from "./pages/ProfileAbout";

function App() {
  const authUser = useAuth(db);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authUser !== undefined) {
      setLoading(false);
    }
  }, [authUser]);

  if (loading) {
    return <Loader />;
  }

  return (
    <Routes>
      {authUser ? (
        <Route path="/" element={<MainLayout />}>
          <Route path="/" element={<Feed />}>
            <Route index element={<Home />} />
            <Route path="/following" element={<Following />} />
            <Route path="/mutual" element={<Mutual />} />
            <Route path="/favorites" element={<Favorites />} />
          </Route>
          <Route path="/search" element={<Search />} />
          <Route path="/hashtag" element={<Hashtag />} />
          <Route path="/hashtag/:hashtag" element={<Hashtag />} />
          <Route path="/explore" element={<>Explorar</>} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/messages/:username/:id" element={<Message />} />
          <Route path="/compose" element={<Compose />} />
          <Route path="/compose/:postId" element={<Compose />} />
          <Route path="/:username/post/:postId/edit" element={<Compose />} />
          <Route path="/:username/post/:postId" element={<PostPage />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/:username" element={<ProfileLayout />}>
            <Route index element={<ProfileHome />} />
            <Route path="/:username/photos" element={<ProfilePhotos />} />
            <Route path="/:username/videos" element={<ProfileVideos />} />
            <Route path="/:username/music" element={<ProfileMusic />} />
            <Route
              path="/:username/shop"
              element={
                <div className="code">Aún no hay productos de este perfil.</div>
              }
            />
            <Route path="/:username/saved" element={<ProfileSaved />} />
            <Route path="/:username/about" element={<ProfileAbout />} />
            {/* <Route path="/:username/loved" element={<></>} /> */}
            <Route
              path="/:username/*"
              element={<div className="code">No tienes acceso a está página.</div>}
            />
          </Route>
          <Route path="/:username/followers" element={<Followers />} />
          <Route path="/:username/following" element={<Followings />} />
          <Route path="/:username/edit" element={<ProfileEdit />} />
          <Route path="/:username/archive" element={<ProfileArchive />} />
          <Route path="/:username/stats" element={<ProfileStats />} />
          <Route path="/login" element={<Navigate to={"/"} replace />} />
          <Route path="/register" element={<Navigate to={"/"} replace />} />
          <Route path="*" element={<Navigate to="/" />} />
          <Route path="/debug" element={<Debug />} />
          <Route path="/comps" element={<Comps />} />
        </Route>
      ) : (
        <>
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Navigate to={"/login"} replace />} />
        </>
      )}
      <Route path="/admin/users" element={<UsersPage />} />
    </Routes>
  );
}

export default App;
