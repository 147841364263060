import { useTranslation } from "react-i18next";

const PostMeta = (props) => {
  const { t } = useTranslation();

  return (
    <div className="post-posted">
      <div>
        {new Date(props.posted.seconds * 1000).toLocaleDateString(
          t("code_lang"),
          {
            day: "numeric",
            month: "short",
            year: "numeric",
            hour: "numeric",
            minute: "numeric",
          }
        )}
      </div>
      <div>Actividad</div>
    </div>
  );
};
export default PostMeta;
