import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";
import Avatar from "./Avatar";

function ItemNotify(props) {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const currentUser = users.find((user) => user.email === auth?.email);

  const getNotificationDetails = () => {
    switch (props.type) {
      case "follow":
        return {
          path: `/${props.currentUserUsername}`,
        };
      case "like":
        return {
          path: `/${props.username}/post/${props.postId}`,
        };
      case "comment":
        return {
          path: `/${props.username}/post/${props.postId}`,
        };
      default:
        return {};
    }
  };

  const { path } = getNotificationDetails();

  return (
    <>
      {props.targetUserId === currentUser?.id && (
        <div className="notification">
          <div className="notification-wrapper">
            <Avatar {...props} />
            <Link to={path} className="notification-link">
              <div className="notification-content">
                <div className="title">
                  {props.currentUserName && props.currentUserUsername && (
                    <b>{`${props.currentUserName} (@${props.currentUserUsername}), `}</b>
                  )}
                  {props.type && (
                    <>
                      {props.type === "follow" ? (
                        <>{t("notifications.follow")}. ✨</>
                      ) : null}
                      {props.type === "like" ? (
                        <>{t("notifications.like")}. ❤️</>
                      ) : null}
                      {props.type === "comment" ? (
                        <>{t("notifications.comment")}. 🪶</>
                      ) : null}
                    </>
                  )}
                </div>
                {props.time && (
                  <div className="time">
                    {new Date(props.time.seconds * 1000).toLocaleDateString(
                      t("code_lang"),
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                      }
                    )}
                  </div>
                )}
              </div>
              <div
                className="notification-photo"
                title={props.text ? props.text : ""}
              >
                {props.photos?.length > 0 ? (
                  <img
                    src={props.photos}
                    alt={props.text}
                    width={50}
                    height={50}
                    loading="lazy"
                  />
                ) : (
                  props.poster && (
                    <img
                      src={props.poster}
                      alt={props.text}
                      width={50}
                      height={50}
                      loading="lazy"
                    />
                  )
                )}
                {props.photos?.length <= 0 && !props.video && props.text && (
                  <img
                    src={"/doc.png"}
                    alt={props.text}
                    width={50}
                    height={50}
                    loading="lazy"
                  />
                )}
              </div>
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default ItemNotify;
