import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MaterialSymbols } from "./MaterialSymbols";
import MainMenuStates from "../utils/MainMenuStates";
import { useAuth } from "../hooks/useAuth";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";
import Avatar from "./Avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell as iconBell,
  faSearch,
  faCompass as iconCompass,
  faMessage as iconMessage,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBell,
  faCompass,
  faMessage,
} from "@fortawesome/free-regular-svg-icons";

function NavMenu(props) {
  const { t } = useTranslation();
  const auth = useAuth(db);
  const users = useUsers(db);
  const currentUser = users.find((user) => user.email === auth?.email);

  const { home, explore, notifications, messages } = MainMenuStates();

  return (
    <nav className="nav-menu">
      <ul className="nav-list">
        <li className="nav-item">
          <Link to="/" className="nav-link">
            <MaterialSymbols icon="home" size="30" fill={home} />
            <span className="nav-title">{t("menu.home")}</span>
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/search" className="nav-link">
            <FontAwesomeIcon icon={faSearch} size="xl" />
            <span className="nav-title">{t("menu.search")}</span>
          </Link>
        </li>
        {false && (
          <li className="nav-item">
            <Link to="/explore" className="nav-link">
              <FontAwesomeIcon
                icon={explore ? iconCompass : faCompass}
                size="xl"
              />
              <span className="nav-title">{t("menu.explore")}</span>
            </Link>
          </li>
        )}
        <li className="nav-item">
          <Link to={"/notifications"} className="nav-link">
            <FontAwesomeIcon
              icon={notifications ? iconBell : faBell}
              size="xl"
            />
            <span className="nav-title">{t("menu.notifications")}</span>
          </Link>
        </li>
        {false && (
          <li className="nav-item">
            <Link to={"/messages"} className="nav-link">
              <FontAwesomeIcon
                icon={messages ? iconMessage : faMessage}
                size="xl"
              />
              <span className="nav-title">{t("menu.messages")}</span>
            </Link>
          </li>
        )}
        <li className="nav-item" id="profile">
          <div className="nav-link">
            <Avatar {...currentUser} />
            <span className="nav-title">{t("menu.profile")}</span>
          </div>
        </li>
      </ul>
    </nav>
  );
}
export default NavMenu;
