import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { website_name } from "../config/app";
import { faCloud } from "@fortawesome/free-solid-svg-icons";

function Loader() {
  return (
    <div className="loader">
      <FontAwesomeIcon
        icon={faCloud}
        title={website_name}
        size="7x"
      />
      <p>{website_name}</p>
    </div>
  );
}
export default Loader;
