import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useUsers } from "../hooks/useUsers";
import { useAuth } from "../hooks/useAuth";
import { db } from "../firebase/config";
import Avatar from "./Avatar";

function PostCommentInput(props) {
  const auth = useAuth(db);
  const users = useUsers(db);
  const { t } = useTranslation();
  const { register, watch } = useForm();
  const currentUser = users.find((user) => user.email === auth?.email);

  return (
    <>
      {currentUser && (
        <div className="post-comment-input">
          <Avatar
            name={currentUser.name}
            picture={currentUser.picture}
            username={currentUser.username}
            width="34"
            height="34"
          />
          <div className="wrapper-field">
            <input
              type="text"
              placeholder={t("post.add_comment") + "..."}
              {...register("text", { required: true, maxLength: 280 })}
              maxLength={280}
            />
            {watch("text") && (
              <div className="counter">{watch("text").length}</div>
            )}
          </div>
          <button type="button" className="button submit">
            {t("post.post")}
          </button>
        </div>
      )}
    </>
  );
}
export default PostCommentInput;
