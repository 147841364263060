import Comment from "../common/Comment";
import { MaterialSymbols } from "../common/MaterialSymbols";

function PostComments(props) {
  return (
    <div className="post-comments">
      {Array.isArray(props.comments) && props.comments.length > 0 ? (
        props.comments
          .sort((a, b) => b.posted - a.posted && a.pin - b.pin)
          .map((comment, index) => (
            <Comment
              key={index}
              {...comment}
              authorPostId={props.id}
              authorPostName={props.name}
              authorPostPicture={props.picture}
            />
          ))
      ) : (
        <div className="comments-empty">
          <MaterialSymbols icon="tooltip_2" size="60" />
          <div className="title">Aún no hay comentarios.</div>
          <div className="subtitle">Sé él primero en comentar.</div>
        </div>
      )}
    </div>
  );
}
export default PostComments;
 