function LinkBox(props) {
  const formatLink = (link) => {
    return link
      .replace(/^https?:\/\//, "")
      .replace(/\?.*$/, "")
      .replace(/\/$/, "");
  };

  const faviconUrl = `https://t3.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${formatLink(
    props.link
  )}&size=50`;

  return (
    <>
      {props.link && (
        <div
          className="link-box"
          style={{
            gap: "12px",
            display: "flex",
            padding: "8px 0",
            width: "100%",
            cursor: "pointer",
            borderBottom: "1px solid var(--compose-border-color)"
          }}
          onClick={() => window.open(props.link)}
        >
          <div className="link-box-logo">
            <img
              src={faviconUrl}
              alt={`Logo de ${props.title}`}
              title={`Logo de ${props.title}`}
              className="link-logo"
              loading="eager"
              width={32}
              height={32}
            />
          </div>
          <div
            className="link-box-data"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {props.title && (
              <span
                className="name"
                style={{ font: "normal 14px var(--font-display)" }}
              >
                {props.title}
              </span>
            )}
            <span className="url">
              <a
                href={props.link}
                target="_blank"
                rel="noopener noreferrer"
                className="url"
                style={{ font: "normal 13px var(--font-display)" }}
              >
                {formatLink(props.link)}
              </a>
            </span>
          </div>
        </div>
      )}
    </>
  );
}
export default LinkBox;
