import { Link, useNavigate } from "react-router-dom";
import { auth } from "../firebase/config";
import { signOut } from "firebase/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faChartSimple,
  faExclamationCircle,
  faGear,
  faSignOut,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
// import { faMoon, faSun } from "@fortawesome/free-regular-svg-icons";
import { MaterialSymbols } from "./MaterialSymbols";
import { useTranslation } from "react-i18next";

function MenuAlt(props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      console.log("Cierre de sesión exitoso");
      navigate("/login");
    } catch (error) {
      console.log("Error al cerrar la sesión", error);
    }
  };

  return (
    <div
      ref={props.menuRef}
      onClick={() => props.handleMenu()}
      className={!props.menu ? "menu-alt" : "menu-alt show"}
    >
      <button type="button" className="link-alt" onClick={props.handleLang}>
        <div className="wrapper-alt">
          <span className="icon-alt">
            <MaterialSymbols icon="translate" size="18" />
          </span>
          <span className="text-alt">{t("menu.language")}</span>
        </div>
      </button>
      {/* <button
        type="button"
        className="link-alt"
        onClick={() => props.handleMode()}
        disabled
      >
        <div className="wrapper-alt">
          <span className="icon-alt">
            <FontAwesomeIcon icon={!props.mode ? faSun : faMoon} size="xl" />
          </span>
          <span className="text-alt">{t("menu.appearance")}</span>
        </div>
      </button> */}
      {/* <Link to="/insights" className="link-alt" disabled>
        <div className="wrapper-alt">
          <span className="icon-alt">
            <FontAwesomeIcon icon={faChartSimple} size="xl" />
          </span>
          <span className="text-alt">{t("menu.stats")}</span>
        </div>
      </Link> */}
      <Link to={"/information"} className="link-alt" disabled>
        <div className="wrapper-alt">
          <span className="icon-alt">
            <FontAwesomeIcon icon={faExclamationCircle} size="xl" />
          </span>
          <span className="text-alt">{t("menu.information")}</span>
        </div>
      </Link>
      <Link to="/settings" className="link-alt" disabled>
        <div className="wrapper-alt">
          <span className="icon-alt">
            <FontAwesomeIcon icon={faGear} size="xl" />
          </span>
          <span className="text-alt">{t("menu.settings")}</span>
        </div>
      </Link>
      <hr />
      <button type="button" className="link-alt" disabled>
        <div className="wrapper-alt">
          <span className="icon-alt">
            <FontAwesomeIcon icon={faTriangleExclamation} size="xl" />
          </span>
          <span className="text-alt">{t("menu.report")}</span>
        </div>
      </button>
      <button
        type="button"
        className="link-alt alert"
        onClick={() => handleSignOut()}
      >
        <div className="wrapper-alt">
          <span className="icon-alt">
            <FontAwesomeIcon icon={faSignOut} size="xl" />
          </span>
          <span className="text-alt">{t("menu.signout")}</span>
        </div>
      </button>
    </div>
  );
}
export default MenuAlt;
