export const MaterialSymbols = (props) => {
  return (
    <span
      className={`material-symbols-rounded${props.fill === true ? " fill" : ""}${
        props.className ? ` ${props.className}` : ""
      }`}
      style={{
        color: props.color,
        fontSize: props.size + "px",
        rotate: props.rotate + "deg",
      }}
    >
      {props.icon}
    </span>
  );
};
