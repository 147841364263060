import { useEffect, useRef, useState } from "react";
import ScrollToTop from "../utils/ScrollToTop";
import Menu from "../common/Menu";
import Main from "../common/Main";
import Loader from "../common/Loader";
import Create from "../pages/Create";
import Language from "../common/Language";
import MenuMobile from "../common/MenuMobile";
import { Helmet } from "react-helmet-async";

function MainLayout() {
  // función que muestra la página de cargando
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  // funcion para abrir/cerrar el submenu menu
  const [menu, setMenu] = useState(false);
  const menuRef = useRef(null);

  const handleMenu = () => {
    setMenu(!menu);
  };

  // función para cerrar el submenu con click en otro lado
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setMenu(false);
    }
  };

  useEffect(() => {
    if (menu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menu]);

  // función para abrir/cerrar los idiomas
  const [lang, setLang] = useState(false);

  const handleLang = () => {
    setLang(!lang);
  };

  // función para abrir/cerrar el creador de publicaciones
  const [create, setCreate] = useState(false);
  const creareRef = useRef(null);

  const handleCreate = () => {
    setCreate(!create);
  };

  return (
    <>
      <Helmet>
        <meta
          name="og:image"
          content={`${window.location.origin}/images/banner-xf-3.png`}
        />
        <meta
          property="twitter:image"
          content={`${window.location.origin}/images/banner-xf-3.png`}
        />
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <div className={"app"}>
          <ScrollToTop />
          <Menu
            menu={menu}
            lang={lang}
            create={create}
            menuRef={menuRef}
            handleMenu={handleMenu}
            handleLang={handleLang}
            handleCreate={handleCreate}
          />
          <MenuMobile
            menu={menu}
            create={create}
            menuRef={menuRef}
            handleMenu={handleMenu}
            handleCreate={handleCreate}
          />
          <Main />
          <Create
            create={create}
            creareRef={creareRef}
            setCreate={setCreate}
            handleCreate={handleCreate}
          />
          <Language lang={lang} handleLang={handleLang} />
        </div>
      )}
    </>
  );
}

export default MainLayout;
