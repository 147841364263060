import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { website_name } from "../config/app";
import { useUsers } from "../hooks/useUsers";
import { db } from "../firebase/config";
import ProfileHeader from "../common/ProfileHeader";
import ProfileContent from "../common/ProfileContent";
import ProfileNavMenu from "../common/ProfileNavMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faClose,
  faEllipsisH,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { goBack } from "../utils/goBack";
import { useState } from "react";
import { MaterialSymbols } from "../common/MaterialSymbols";
import { copyProfileLink } from "../utils/copyProfileLink";

function ProfileLayout() {
  const users = useUsers(db);
  const { username } = useParams();
  const user = users.find((user) => user.username === username);

  const [popUp, setPopUp] = useState(false);

  const openPopUp = () => {
    setPopUp(!popUp);
  };

  return (
    <>
      {user ? (
        <>
          <Helmet>
            <title>
              {`${user.name} (@${user.username}) ~ ${website_name}`}
            </title>
            <link
              rel="canonical"
              href={`${window.location.origin}/${username}`}
            />
            {/* <!-- Primary Meta Tags --> */}
            <meta
              name="title"
              content={`${user.name} (@${user.username}) ~ ${website_name}`}
            />
            {user.bio && <meta name="description" content={user.bio} />}

            {/* <!-- Open Graph / Facebook --> */}
            <meta name="og:type" content="website" />
            <meta
              name="og:url"
              content={`${window.location.origin}/${username}`}
            />
            <meta
              name="og:title"
              content={`${user.name} (@${user.username}) ~ ${website_name}`}
            />
            {user.bio && <meta name="og:description" content={user.bio} />}
            <meta
              name="og:image"
              content={user.picture ? user.picture : "/images/avatar.png"}
            />

            {/* <!-- Twitter --> */}
            <meta property="twitter:card" content="summary" />
            <meta
              property="twitter:url"
              content={`${window.location.origin}/${username}`}
            />
            <meta
              property="twitter:title"
              content={`${user.name} (@${user.username}) ~ ${website_name}`}
            />
            {user.bio && (
              <meta property="twitter:description" content={user.bio} />
            )}
            <meta
              property="twitter:image"
              content={user.picture ? user.picture : "/images/avatar.png"}
            />
          </Helmet>
          <div className="profile page">
            <div className="page-header">
              <button
                type="button"
                className="button icon"
                onClick={() => goBack()}
                title="Volver atrás"
              >
                <FontAwesomeIcon icon={faArrowLeft} size="lg" />
              </button>
              <h1 className="title">{`${user.name} (@${username})`}</h1>
              <button
                type="button"
                className="button icon"
                onClick={() => openPopUp()}
                style={{ zIndex: "12" }}
              >
                <FontAwesomeIcon
                  icon={popUp ? faClose : faEllipsisH}
                  size="lg"
                />
              </button>
            </div>
            <div className="profile-header">
              <ProfileHeader {...user} />
              <ProfileNavMenu {...user} username={username} />
            </div>
            <ProfileContent />
          </div>
          <div className={popUp ? "popup show" : "popup"}>
            <div className="profile-menu" onClick={() => setPopUp(false)}>
              <button type="button" className="profile-menu-item" disabled>
                <div className="wrapper">
                  <div className="icon">
                    <MaterialSymbols icon="person_off" />
                  </div>
                  <div className="text alert">Selenciar a @{username}</div>
                </div>
              </button>
              <button type="button" className="profile-menu-item" disabled>
                <div className="wrapper">
                  <div className="icon">
                    <MaterialSymbols icon="block" />
                  </div>
                  <div className="text alert">Bloquear a @{username}</div>
                </div>
              </button>
              <button type="button" className="profile-menu-item" disabled>
                <div className="wrapper">
                  <div className="icon">
                    <MaterialSymbols icon="report" />
                  </div>
                  <div className="text">Denunciar a @{username}</div>
                </div>
              </button>
              <hr />
              <button
                type="button"
                className="profile-menu-item"
                onClick={() => copyProfileLink(username)}
              >
                <div className="wrapper">
                  <div className="icon">
                    <MaterialSymbols icon="link" rotate="-45" />
                  </div>
                  <div className="text">Copiar enlace al perfil</div>
                </div>
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="page">
            <div className="page-header">
              <button
                type="button"
                className="button icon"
                onClick={() => goBack()}
                title="Volver atrás"
              >
                <FontAwesomeIcon icon={faArrowLeft} size="lg" />
              </button>
              <h1 className="title">{`@${username}`}</h1>
              <button type="button" className="button icon" disabled>
                <FontAwesomeIcon icon={faQuestion} size="lg" />
              </button>
            </div>
            <div className="page-content">
              <h2>Este perfil no existe</h2>
              <span>Intenta hacer otra búsqueda.</span>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ProfileLayout;
