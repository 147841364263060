// Expresiones regulares
const urlRegex = /(https?:\/\/)?(www\.)?[\w-]+\.[\w.-]+(\/[\w-./?%&=]*)?/gi;

// Función para formatear texto con enlaces interno y externos
export const formatLink = (text) => {
  // Reemplazar URLs
  return text.replace(urlRegex, (url) => {
    // Asegurar que el enlace tenga el protocolo correcto
    const formattedUrl = url.startsWith("http") ? url : `https://${url}`;

    // Quitar el http/https del texto visible
    const visibleText = formattedUrl
      .replace(/https?:\/\//, "")
      .replace(/\?.*$/, "");

    return `<a href="${formattedUrl}" target="_blank" rel="noopener noreferrer">${visibleText}</a>`;
  });
};
